/* eslint-disable max-len */
import links from "app/core/resource/links";

export default {
    main: {
        title: "О компании",
        description: `
                <p>
                    Электронная медицинская информационная система apteka24.ua сотрудничает с несколькими сотнями аптеками-партнерами, благодаря которым клиенты получают возможность бронирования лекарств с полки ближайшей аптеки.
                </p>

                <p>
                    Заказывая лекарства и товары медицинского назначения через электронную медицинскую информационную систему apteka24.ua, вы:
                </p>
                
                <ul>
                    <li>
                        находите на сайте лекарственное средство, которого нет во многих аптеках;
                    </li>
                    
                    <li>
                        в один клик бронируете товар;
                    </li>
                    
                    <li>
                        забираете его в аптеке, которая к вам ближе всего;
                    </li>
                    
                    <li>
                        можете получить курьерскую доставку по адресу.
                    </li>
                </ul>

                <p>
                    Кроме того, сайт apteka24.ua — это портал образовательного формата с информацией о медицине, лекарственных препаратах, а также о здоровье и благополучии как таковых. Мы, в apteka24.ua, предоставляем пользователям возможность ознакомиться с обширными медицинскими данными по вопросам здоровья и применению лекарственных средств. Для нас очень важно быть источником надежной, достоверной и научно доказанной информации, которая была бы полезна людям и помогала искоренить путаницу и дезинформацию в медицинской сфере.
                </p>
                `,
        keyFactsAboutUs: `Ключевые факты о нас`,
        vision: `<h3>Видение</h3>

                <p>
                    Ломать стереотипы аптечного бизнеса.
                </p>`,
        mission: `<h3>Миссия</h3>

                <p>
                    Мы стремимся дать клиентам сервис и знания, необходимые для более эффективного управления
                    своим собственным здоровьем, а также повышать безопасность лечения, помогая пациентам
                    сократить количество ошибок при применении лекарств.
                </p>`,
        worth: `<h3>Ценности</h3>

                <p>
                    Наши ценности: открытость, достоверность, вовлеченность и гарантии качества.
                </p>`,
        openness: `<p>
                        <strong>
                            Открытость
                        </strong>
                    </p>

                    <p>
                        Прозрачность — один из основных наших принципов, ведь именно на нем строится доверие.
                        Мы открыто рассказываем о хитростях, к которым прибегают в аптечной сфере, или о том,
                        почему брендовые лекарства не хуже дженериков.
                    </p>

                    <p>
                        Все, что мы делаем — прозрачно. Поэтому мы сами открыто заявляем об источниках нашего
                        финансирования, демонстрируем все наши сертификаты и лицензии, а также предупреждаем о
                        возможном конфликте интересов там, где он может повлиять на вас.
                    </p>`,
        reliability: `<p>
                        <strong>
                            Достоверность
                        </strong>
                    </p>

                    <p>
                        Наш основной принцип  — сообщать только научно доказанную информацию. Сегодня интернет
                        пестрит искаженной либо дезориентирующей информацией о медицине, а большинство сайтов о
                        здоровье заполняют маркетологи, которые не задумываются о качестве и достоверности
                        предоставляемых медицинских данных. К тому же, в медицине постоянно происходят изменения и
                        новые открытия, однако большинство информационных ресурсов не следят за актуальностью
                        информации. Это приводит к тому, что люди читают недостоверные или устаревшие статьи,
                        которыми руководствуются при лечении болезней. Возрастает риск ошибок во время применения
                        лекарственных средств.
                    </p>

                    <p>
                        Мы поддерживаем сознательную медицину, поэтому предоставляем достоверную информацию,
                        проходящую несколько этапов проверки: начиная от подбора официальных источников и заканчивая
                        проверкой фактов медицинским экспертом. Подробнее о процессе верификации материалов вы
                        можете узнать из нашей&nbsp;
                        <a target="_blank" href="${links.editorialPolicy}">
                            редакционной политики.
                        </a>
                    </p>

                    <p>
                        Инструкции к препаратам мы публикуем в соответствии с&nbsp;
                        <a target="_blank" href="${links.stateRegisterOfDrugs}">Державним реєстром лікарських засобів України.</a>
                        &nbsp;Но не ограничиваемся этим. Мы понимаем, что инструкции в большинстве случаев
                        непонятны многим людям, поэтому ознакомлением с ними пренебрегают. В связи с этим, мы
                        предоставляем подробное и доступное описание препаратов на сайте, а также рекомендации по
                        их применению, подчеркиваем взаимодействие с другими веществами и акцентируем внимание на
                        особенностях применения у различных возрастных групп и в зависимости от наличествующих
                        заболеваний. Мы предоставляем инструкции понятным языком.
                    </p>`,
        involvement: `<p>
                            <strong>
                                Вовлеченность
                            </strong>
                        </p>

                        <p>
                            Для нас не бывает поверхностных проблем, а, соответственно, не бывает и поверхностных
                            решений. Каждую возникающую в компании проблему мы решаем с полной вовлеченностью,
                            погружаясь в ее первопричины и задействуя всех участников команды.
                        </p>

                        <p>
                            Такой же подход мы используем и в решении ваших проблем. Когда звонок с сообщением о
                            проблеме поступает оператору колл-центра, информация обязательно передается менеджерам и
                            задействуются все члены команды, которые не лягут спать, пока не решат проблемный вопрос.
                            После этого мы всегда лично перезваниваем клиенту, чтобы принести свои извинения от всей
                            команды и сообщить, что вопрос решен.
                        </p>`,
        qualityAssurance: `
                        <p>
                            <strong>
                                Гарантии качества
                            </strong>
                        </p>

                        <p>
                            Как электронная медицинская информационная система мы видим своей задачей организацию выбора и доставки качественной продукции аптечного ассортимента клиенту. Поэтому мы сотрудничаем с надежными аптеками-партнерами, которым товары медицинского назначения поставляют только крупные и проверенные дистрибьюторы в Украине.
                        </p>
                        
                        <p>
                            В свою очередь аптеки-партнеры осуществляют проверку всех поступающих товаров через систему трёхуровневого контроля:                        
                        </p>
                        
                        <ul>
                            <li>
                                каждый этап реализации лекарственных средств сопровождается четким контролем температурного режима и влажности (первая проверка происходит при поступлении продукции на склад, вторая — при выходе со склада в аптеку, третья — при приеме товара в аптеке);
                            </li>
                                                    
                            <li>
                                в аптеках происходит отслеживание продукции с истекающим сроком годности, товар с истекающим сроком годности изымается заранее из реализации;
                            </li>
                                                    
                            <li>
                                при поступлении продукции в аптеки-партнеры уполномоченное лицо проводит входной контроль, проверяет лекарственные средства на отсутствие в Реестре документов качества лекарственных средств, обращение которых запрещено в Украине, а также проводит визуальный контроль по таким показателям как «Маркировка», «Упаковка», «Срок годности».
                            </li>
                        </ul>
                        
                        <p>
                            К тому же, в каждом аптечном заведении, с которым мы сотрудничаем, покупатель может попросить предоставить сертификаты качества на продукцию и информацию о производителе или дистрибьюторе — и эти документы будут предоставлены.
                        </p>
                        `,
        policiesAndRules: `<h3>Политики и правила</h3>

                        <p>
                            Мы четко регламентируем свою деятельность, опираясь на ряд внутренних документов,
                            регулирующих все, что происходит в нашей компании. И мы предоставляем эти документы вам в
                            соответствии с нашим принципом прозрачности.
                        </p>`,
        editorialPolicy: `<p>
                            <strong>
                                <a target="_blank" href="${links.editorialPolicy}">Редакционная политика</a>
                            </strong>
                            &nbsp;регулирует процесс создания контента от и до, направляет нашу деятельность в
                            соответствии с принципами и обозначает строгие критерии качества для каждого типа контента
                            на сайте.
                        </p>`,
        marketingPolicy: `<p>
                            <strong>
                                <a target="_blank" href="${links.marketingPolicy}">Маркетинговая политика</a>
                            </strong>
                            &nbsp;заявляет о наших отношениях с рекламодателями, а также обозначает нашу позицию
                            касательно рекламы и спонсорского контента.
                        </p>`,
        reviewPolicy: `<p>
                            <strong>
                                <a target="_blank" href="${links.reviewsPostingPolicy}">Политика публикации отзывов</a>
                            </strong>
                            &nbsp;призвана регулировать отношения между пользователями apteka24.ua и их слововыражением
                            на сайте, акцентируя внимания на цензуре, взаимоуважении и правдивости предоставляемой
                            информации из личного опыта.
                        </p>`,
        privacyPolicy: `<p>
                            <strong>
                                <a target="_blank" href="${links.privacyPolicy}">Политика конфиденциальности</a>
                            </strong>
                            &nbsp;предупреждает о сборе персональных данных и возможности их использования со стороны
                            apteka24.ua.
                        </p>`,
        userAgreement: `<p>
                            <strong>
                                <a target="_blank" href="${links.userAgreement}">Соглашение об использовании</a>
                            </strong>
                            &nbsp;устанавливает правила и условия использования сайта пользователями, которые после
                            регистрации или без таковой, пользуются сайтом, его службами и услугами, предоставляемыми
                            apteka24.ua.
                        </p>`,
        returnConditions: `<p>
                            <strong>
                                <a target="_blank" href="${links.orderReturn}">Условия возврата</a>
                            </strong>&nbsp;&mdash;
                            документ, в котором обозначены согласно законам Украины, товары медицинского назначения надлежащего качества, которые не подлежат обмену и возврату, а также определенные условия и процедуру, при которых товары ненадлежащего качества можно обменять или вернуть.
                        </p>`,
        warranty: `<p>
                            <strong>
                                <a target="_blank" href="${links.warranty}">Гарантии качества</a>
                            </strong>&nbsp;&mdash;
                            это наш скрупулезный отчет о том, как, при каких условиях и с соблюдением каких правил хранится и транспортируется продукция аптечного ассортимента через наши аптеки-партнеры.
                        </p>`,
        targetAudience: `<h3>Целевая аудитория</h3>

                        <p>
                            Наша целевая аудитория — это все население Украины. Мы придерживаемся инклюзивного подхода
                            и помогаем всем людям без исключения, вне зависимости от их пола, возраста, статуса, места
                            жительства и физиологических особенностей.
                        </p>`,
        wideAssortment: `<h3>Широкий ассортимент</h3>

                        <p>
                            Электронная медицинская информационная система apteka24.ua предлагает один из самых широких ассортиментов средств аптечного ассортимента и медицинских товаров в Украине. Каталог товаров, которые можно найти в аптеках-партнерах, включает более 15 000 наименований, среди которых есть даже редкие и эксклюзивные препараты.
                        </p>`,
        license: ``,
        contacts: `<h3>Контактная информация</h3>

                        <p>
                            Юридическое лицо:&nbsp;
                            <span>
                                ООО «МИС-Cервис» apteka24.ua.
                            </span>
                        </p>

                        <p>
                            Головной офис apteka24.ua располагается по адресу:
                        </p>

                        <p>
                            Украина, 49100, Днепропетровская обл., город Днипро, пр.Героев, дом 11Л, кабинет 321.
                        </p>

                        <p>
                            Горячая линия:
                        </p>
                        <p>
                            Связаться со  службой  поддержки вы  можете  по адресу электронной почты:&nbsp;

                            <a href="mailto:client@apteka24.ua">client@apteka24.ua</a>
                        </p>`,
        readMoreInfo: `<header class="about-section__header">
                    <h2>Узнайте больше о нас</h2>
                </header>

                <div class="about-section__body">
                    <p>
                        Мы считаем, что осознанное отношение к здоровью проистекает из образованности, понимания
                        физиологических и психических процессов, чему способствует получение достоверной и научно
                        доказанной информации в сфере медицины, здравоохранения и человеческого благополучия.
                    </p>

                    <p>
                        Мы придерживаемся этого видения в своей работе, ведем просветительскую деятельность по вопросам
                        здоровья, даем людям возможность получить необходимую помощь и пытаемся уберечь наших
                        пользователей от самолечения, напоминая, что только квалифицированный  врач может поставить
                        верный диагноз и назначить правильное лечение.
                    </p>

                    <p>
                        В тоже время, мы осознаем, что людей, прибегающим к самолечению, достаточно много. Поэтому мы
                        публикуем большое количество информационного медицинского контента по вопросам здоровья и
                        применения лекарственных средств для таких посетителей, чтобы помочь им лучше разобраться в
                        вопросах здоровья, предупредить о симптомах передозировки, побочных эффектах, особенностях
                        применения, возрастных ограничениях, влиянии каждого препарата на ткани, органы и системы
                        органов, предупреждаем о взаимодействии лекарственных средств и опасности применения комбинаций
                        препаратов без консультации врача.
                    </p>

                    <div class="about-subsection">
                        <h3>Наша цель и методы достижения</h3>

                        <p>
                            Нашу основную цель мы видим в том, чтобы стать самым надежным источником информации о лекарствах, медицине, здоровье и достижении благополучия, а также в упрощении поиска и заказа товаров аптечного ассортимента клиентами.
                        </p>

                        <p>
                            Методы достижения цели — заключение сотрудничества с аптеками-партнерами для предоставления информации и возможности для поиска и заказа товаров аптечного ассортимента клиентам, а также предоставление независимого, объективного, полного и актуального медицинского информирования в четком и лаконичном изложении, которое доступно и понятно не только работникам сферы здравоохранения, но и обычным людям.
                        </p>
                    </div>

                    <div class="about-subsection">
                        <h3>Наши задачи</h3>

                        <p>
                            В своей деятельности мы выделяем три основные задачи:
                        </p>

                        <ul>
                            <li>
                                просветительская работа по вопросам здоровья, медицины и благополучия, ведущаяся в
                                образовательном формате;
                            </li>

                            <li>
                                реализация нашей позиции связующего звена между пациентом и врачом, что мы воплощаем в
                                жизнь путем привлечения людей к обращению к медицинскому специалисту;
                            </li>

                            <li>
                                предоставление возможности ознакомиться с инструкциями, описаниями и характеристиками
                                препаратов, а также приобрести назначенные врачом лекарственные средства и товары
                                медицинского назначения удобным способом посредством онлайн-заказа в интернет-аптеке.
                            </li>
                        </ul>
                    </div>

                    <div class="about-subsection">
                        <h3>Этика нашей компании</h3>

                        <strong>
                            Наш главный этический принцип — приносить пользу людям.
                        </strong>

                        <p>
                            Поэтому мы предоставляем только достоверную, актуальную и научно обоснованную информацию,
                            проверенную как редакционной командой, так и&nbsp;
                            <a target="_blank" href="/team/medical-experts/">командой медицинских экспертов</a>. Мы не сообщаем
                            дезинформацию, поэтому тщательно проверяем первоисточники и научные исследования. Для нас
                            крайне важно, чтобы все материалы на сайте были полезными для пользователей. Информацию мы
                            подаем доступно и понятно.
                        </p>

                        <p>
                            Помогая совершить заказ и оформить доставку лекарственных средств и товаров медицинского назначения, мы гарантируем качество продукции, реализуемой через наши партнерские аптеки, наличие сертификатов и лицензий у товаров аптечного ассортимента, соответствие условиям хранения и транспортировки, начиная со склада до продажи товаров конечному потребителю.
                        </p>

                        <p>
                            Мы не продаем рецептурные препараты без предъявления рецепта  клиентом, а термолабильные
                            препараты мы отпускаем из аптек только в соответствующем термоконтейнере с хладоэлементами.
                        </p>

                        <p>
                            Через "МИС apteka24.ua" вы можете забронировать рецептурные препараты, однако купить их вы можете только в аптеке-партнере и только после предъявления рецепта, выписанного врачом. Термолабильные препараты из аптек-партнеров отпускаются только в соответствующем термоконтейнере с хладоэлементами.
                        </p>

                        <p>
                            Каждый аспект нашей деятельности соответствует законам Украины:                        
                        </p>

                        <ul>
                            <li>
                                <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/123/96-%D0%B2%D1%80#Text">
                                    Закону Украины «О лекарственных средствах» от 04.04.96 №123;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/220-2020-%D0%BF#Text">
                                    Постановлению Кабинета Министров Украины от 23.03.2020 №220;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="http://pravoved.in.ua/section-law/158-zuoapisp.html">
                                    Закону Украины «Об авторском праве и смежных правах».
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="about-subsection">
                        <h3>База данных и источники информации</h3>

                        <p>
                            Для предоставления надежных и достоверных данных о лекарственных препаратах, товарах медицинского назначения, о здоровье и медицине на сайте электронной медицинской информационной системы apteka24.ua мы используем официальные источники (государственные, национальные и международные сайты, ресурсы, библиотеки, базы знаний) и источники с научной доказательной базой.
                        </p>

                        <p>
                            К официальным источникам информации относятся:
                        </p>

                        <ul>
                            <li>
                                <a target="_blank" href="https://www.who.int/ru">
                                    Всемирная организация здравоохранения;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://moz.gov.ua/">
                                    Министерство здравоохранения Украины;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/2801-12#Text">
                                    Закон Украины «Основи законодавства України про охорону здоров&apos;я»;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://zakon.rada.gov.ua/laws/show/123/96-%D0%B2%D1%80#Text">
                                    Закон Украины «Про лікарські засоби»;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="http://www.drlz.com.ua/">
                                    Державний реєстр лікарських засобів України;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://www.nlm.nih.gov/">
                                    Национальная библиотека медицины США;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/">
                                    База данных Национального центра биотехнологической информации США;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://www.cdc.gov/">
                                    Центр по контролю и профилактике заболеваний;
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://www.fda.gov/">
                                    Управление по санитарному надзору за качеством пищевых
                                    продуктов и медикаментов (FDA).
                                </a>
                            </li>
                        </ul>

                        <p>
                            Инструкции и описания лекарственных средств и товаров медицинского назначения составляются
                            на основании данных официальных инструкций&nbsp;
                            <a target="_blank" href="http://www.drlz.com.ua/">Державного реєстру лікарських засобів України</a>,
                            национальных и международных справочников по лекарственным средствам&nbsp;
                            <a target="_blank" href="https://www.drugs.com/">(Drugs.com)</a>, а также
                            согласно официальным данным производителя.
                        </p>
                    </div>

                    <div class="about-subsection">
                        <h3>Информационная база знаний (блог)</h3>

                        <p>
                            На пути к цели, заключающейся в просветительском информировании людей по вопросам здоровья,
                            мы создали информационную базу знаний в формате блога, в котором редакция освещает
                            медицинские и социально значимые темы, осведомленность в которых необходима для
                            благополучия человека.
                        </p>

                        <p>
                            Материалы блога содержат достоверную, точную, надежную и актуальную информацию, источниками
                            которой выступают государственные, национальные и международные медицинские сайты, ресурсы,
                            энциклопедии, справочники и базы знаний.
                        </p>

                        <p>
                            Каждая статья тщательно исследуется, а источники проверяются на предмет актуальности,
                            достоверности и объективности.
                        </p>

                        <p>
                            <a target="_blank" href="/team/">Авторов редакции</a>, пишущих статьи, мы обучаем передовым методам ведения
                            исследовательской журналистской деятельности, поиску лидеров мнений и профессиональных
                            экспертов, а также регулярно предоставляем им обратную связь и беспрерывное обучение.
                        </p>

                        <p>
                            Каждая статья блога медицинской тематики проверяется группой профессиональных медиков,
                            фармацевтов или редакторов для обеспечения точности, достоверности, актуальности и
                            релевантности. Верификацию статей проводит&nbsp;
                            <a target="_blank" href="/team/medical-experts/">наша команда медицинских экспертов</a>.
                            Участники данной команды  — это высококвалифицированные специалисты широкого спектра
                            медицинских отраслей, у которых за плечами большой опыт клинической практики, исследований
                            и защиты интересов пациентов.
                        </p>
                    </div>

                    <div class="about-subsection">
                        <h3>Каталог товаров и медикаментов</h3>

                        <p>
                            Мы являемся электронной медицинской информационной системой, предоставляющей возможность пользователям и медицинским специалистам заказывать и бронировать лекарственные средства и товары медицинского назначения.
                        </p>

                        <p>
                            Для реализации этой цели мы предлагаем каталог товаров и медикаментов, в котором представлено более 15 000 товаров медицинского назначения.
                        </p>
                        
                        <p>
                            Каждый товар в обязательном порядке содержит актуальное фото, 
                            официальную инструкцию по применению, соответствующую 
                            <a target="_blank" href="http://www.drlz.com.ua/">
                                Державному реєстру лікарських засобів України
                            </a>, а также подробную информацию, 
                            описание и характеристики товара, позволяющие пользователям и медицинским работникам узнать 
                            все необходимые данные о лекарственном средстве или товаре медицинского назначения.                        
                        </p>

                        <p>
                            Вся информация в каталоге соответствует официальным источникам и обновляется согласно 
                            возможным изменениям в 
                            <a target="_blank" href="http://www.drlz.com.ua/">
                                Державному реєстру лікарських засобів України
                            </a> с периодичностью 
                            не реже раза в год.
                        </p>

                        <p>
                            Однако мы не призываем посетителей сайта использовать предоставленные инструкции в качестве руководства по самолечению. Помните, что назначать лечение, дозировки и способ приема препарата может только ваш лечащий врач.
                        </p>
                    </div>

                    <div class="about-subsection">
                        <h3>Реклама</h3>

                        <p>
                            В электронной медицинской информационной системе apteka24.ua может размещаться реклама и спонсируемый контент. Однако мы абсолютно уверены в своей редакционной автономии и никогда не позволяем спонсорам влиять на создаваемый нами контент и оказываемые услуги.
                        </p>

                        <p>
                            У нас есть особые правила по рекламе и спонсорству, которым мы следуем, чтобы реклама никогда не мешала редакционной честности и надежности.
                        </p>

                        <p>
                            Вся реклама, размещенная в электронной медицинской информационной системе apteka24.ua, соответствует принципу полезности и обоснованности. Мы сами выбираем рекламу, не позволяем нам навязывать точку зрения рекламодателя и никогда не станем рекламировать то, что может потенциально навредить, является неоправданно дорогим или не несет доказательной пользы. Мы четко различаем спонсируемый и не спонсируемый контент и не допускаем пересечения одного с другим.
                        </p>
                    </div>

                    <div class="about-subsection">
                        <h3>Источники финансирования</h3>

                        <p>
                            Мы получаем финансирование от рекламы и спонсируемого контента, а также от совершения заказов и бронирования лекарственных средств и товаров медицинского назначения в нашей электронной медицинской информационной системе. В некоторых информационных статьях блога также могут размещаться ссылки на товары, размещенные на нашем сайте. При переходе пользователя по таким ссылкам и при заказе такого препарата мы можем получать прибыль.
                        </p>

                        <p>
                            Реальность такова, что финансирование данными способами позволяет нам покрывать большее количество затрат на создание качественного контента и предоставлять более эффективный сервис по обслуживанию клиентов электронной медицинской информационной системе apteka24.ua.
                        </p>
                    </div>

                    <div class="about-subsection">
                        <h3>Конфликт интересов</h3>

                        <p>
                            Вся информация, представляющая собой описания лекарственных препаратов, диетических добавок 
                            и товаров медицинского назначения размещена в соответствии с официальными инструкциями из 
                            <a target="_blank" href="http://www.drlz.com.ua/">
                                Державного реєстра лікарських засобів України
                            </a>, а также на основании проверенных 
                            источников медицинской информации.
                        </p>

                        <p>
                            Статьи блога представлены в соответствии с официальными данными отечественных и зарубежных информационных источников, которые указываются в тексте. Статьи медицинской тематики, содержащие обобщенную информацию о заболеваниях, состояниях, лечении, подготовлены редакцией электронной медицинской информационной системе apteka24.ua.
                        </p>

                        <p>
                            Все материалы на сайте размещаются в соответствии с высокими этическими принципами 
                            МИС apteka24.ua и не подвержены влиянию спонсоров или иных третьих лиц.
                        </p>

                        <p>
                            Тем не менее конфликт интересов может заключаться в том, что некоторые специалисты 
                            (фармацевты и врачи), входящие в состав 
                            <a target="_blank" href="/team/medical-experts/">команды медицинских экспертов</a>, являются 
                            сотрудниками МИС apteka24.ua и получают денежное вознаграждение за свою работу, 
                            что может сказываться на их позиционировании и подаче информации в контенте сайта.
                        </p>

                        <p>
                            apteka24.ua не несет ответственности за личное мнение и позиционирование каждого специалиста, однако старается минимизировать заведомо неправдоподобную либо не полезную информацию для посетителей сайта, отказываясь от публикаций подобных статей.
                        </p>

                        <p>
                            Публикуемый контент должен быть полезным для здоровья и благополучия читателей и не причинять вред их физическому и ментальному здоровью.
                        </p>

                        <p>
                            Врачи и другие специалисты из области здоровья и благополучия сотрудничают с электронной медицинской информационной системой apteka24.ua на благотворительных началах и не получают за свою деятельность финансовое вознаграждение, что не исключает, но значительно снижает конфликт интересов.
                        </p>
                    </div>
                </div>`
    },
    partners: {
        title: "Наши партнёры"
    },
    howto: {
        title: "Как сделать заказ",
        headText: `Несмотря на то, что совершить покупку на нашем сайте очень просто, мы все же расскажем вам, как
                        это сделать, чтобы у вас вообще не осталось никаких вопросов ;-)`,
        howProcessGoing: `Как проходит процесс`,
        findProduct: `Находите нужный товар`,
        registerOrder: `Оформляете заказ`,
        choosePaymentMethod: `Выбираете способ оплаты`,
        awaitingParcel: `Ожидаете посылку :)`,
        find: `№1 Как найти товар?`,
        searchMethods: `Найти любой товар можно несколькими способами — вам только остается выбрать,
                        какой из них для вас комфортнее.`,
        searchMethod1: `<b>Способ №1:</b> вписать название препарата или товара в поиск.`,
        searchMethod2: `<b>Способ №2:</b> перейти в каталог, побродить по джунглям лекарственных средств и
                        выбрать то, что вы искали.`,
        buy: `№2 Как совершить покупку?`,
        buyInstruction: `Выбираете товар, переходите к нему — и тут же увидите всю нужную информацию: инструкцию,
                                описание, характеристики, варианты доставки и, конечно, две кнопки «Купить» и «Купить в
                                один клик».`,
        buttonsFeatures: ` В чем особенность этих двух кнопок?`,
        oneClickBuyButton: `Если вам совершенно не хочется заморачиваться с оформлением заказа, нажмите
                                    <strong> «Купить в один клик»</strong> , чтобы оставить во всплывшем окошке свой
                                    номер телефона — вам позвонит наш оператор, уточнит все детали и оформит заказ
                                    вместо вас. Удобно, не так ли? ;-)`,
        buyButton: `А вот для тех, кто жаждет все делать самостоятельно, есть кнопка <strong>«Купить»</strong> —
                    здесь уже нужно будет самому заполнить все поля и ввести данные.
                    Там их не так много, не пугайтесь! ;-)`,
        modals: `Во всплывающих окнах можно выбрать <strong>город, куда нужно доставить товары</strong>, определиться со
                <strong> способом доставки</strong> (в аптеку самовывозом или курьерскими службами в отделение или по
                указанному адресу) и со <strong>способом оплаты</strong> (наличными или картой).`,
        itLookLike: `Выглядит это всё вот так:`,
        addCertificate: `Между прочим, если вы поставите галочку в графе <strong>«Добавить сертификат к товарам»</strong>
                        (после выбора способа доставки), то мы пришлем сертификаты качества,
                        чтобы вы могли убедиться в надежности лекарств.`,
        pushOrderButton: `Напоследок нажимаете кнопку <strong>«Оформить заказ»</strong>  — и все, на этом ваша работа закончена и
                        начинается наша.`,
        pay: `№3 Как оплатить?`,
        payDescription: `<p>
                            Деньгами ;-) Причем, как наличными, так и безналичными.
                        </p>

                        <p>
                            При желании можно оплатить заказ наличными (наложенным платежом) курьеру,
                            в отделении службы доставки или непосредственно в аптеке.
                        </p>

                        <p>
                            Если вы не любитель снимать деньги в банкомате,
                            то оплата картой на нашем сайте для вас тоже есть.
                        </p>

                        <p>
                            Между прочим, при самовывозе из аптеки также можно расплатиться картой.
                        </p>

                        <p>
                            <strong>Приятных покупок!</strong>
                        </p>`
    },
    delivery: {
        title: "Доставка и оплата",
        warning: {
            description: `<span class="f-weight-5">Внимание</span>: адресная доставка не распространяется на рецептурные лекарственные препараты, препараты из перечня наркотических средств; психотропные, сильнодействующие или ядовитые вещества, а также препараты, требующие особых условий хранения и транспортировки!`
        },
        deliveryTypes: {
            title: "Способы доставки",
            items: [
                {
                    title: "Курьер «apteka24.ua»",
                    subtitle: "Быстрая доставка на сегодня или на завтра",
                    preview: links.images.mobileApp.delivery.courier,
                    advantages: [
                        `Тариф за доставку заказов составляет <span class="f-weight-5">70 грн</span>`,
                        `Минимальная сумма заказа 250 грн.`,
                        `Оплата картой на сайте или наличными курьеру`
                    ]
                },
                {
                    title: "Доставка Новой почтой",
                    subtitle: "Отправка товаров Новой почтой",
                    preview: links.images.mobileApp.delivery.post,
                    advantages: [
                        `Доставка в отделение Новой почты - <span class="f-weight-5">70 грн</span>`,
                        `Доставка курьером Новой почты - <span class="f-weight-5">70 грн</span>`,
                        `Оплата наличными или картой на сайте`
                    ]
                },
                {
                    title: "Самовывоз из аптеки",
                    // subtitle: `Забрать товары можно бесплатно в одной <a href=${links.pharmacy} target="_blank">из 1000 наших аптек по всей Украине</a>`,
                    preview: links.images.mobileApp.delivery.pickup,
                    advantages: [
                        `При наличии товара на полке в аптеке получение возможно в день заказа`,
                        `Цены на сайте отличаются от цен в аптеках — на сайте они ниже`,
                        `Оплата наличными или картой при получении`,
                        `Бесплатная доставка заказов`
                    ]
                }
            ]
        },
        paymentTypes: {
            title: "Способы оплаты",
            items: [
                {
                    title: "Оплата наличными при получении",
                    preview: links.icons.cash,
                    description: "Оплата наличными при получении доступна для любых способов доставки. Вы можете оплатить заказ наличными при получении в наших аптеках, в отделениях служб доставки или при встрече с курьером."
                },
                {
                    title: "Оплата картой на сайте",
                    preview: links.icons.credit,
                    description: "Оплата банковской картой доступна для любых способов доставки. Вы можете оплатить заказ при его оформлении или позже — после получения от нас сообщения с подтверждением заказа. Заказы, которые доставляются в аптеку, можно оплатить непосредственно при получении. Мы не берем дополнительную комиссию за оплату картой."
                },
                {
                    title: "Использование бонусов и промокодов",
                    preview: links.icons.receipt,
                    description: "Для экономии на лекарствах вы можете при оформлении заказа использовать промокод или бонусы. Обратите внимание, что действие промокода может быть ограничено по времени или сумме заказа. Если у вас нет промокода, вы можете применить к заказу накопленные бонусы. Баланс бонусов можно проверить в личном кабинете."
                },
                {
                    title: "Возврат денежных средств",
                    preview: links.icons.doubleRefresh,
                    description: "Возврат средств за отмененный заказ может занимать до 3 банковских дней. Возврат по заказам, доставляемым через службу Укрпошты, после оформления которых прошло менее 2 часов, поступает на карту сразу после отмены заказа."
                }
            ]
        }
    },
    mission: {
        title: "Наша миссия",
        alertInfo: `Мы стремимся дать клиентам сервис и знания, необходимые для более эффективного управления своим
                        собственным здоровьем, а также обеспечить безопасность лечения, помогая пациентам сократить
                        количество ошибок при приеме лекарств.`,
        wayTitle: `Почему наш путь таков?`,
        wayBody: `<p>
                        Количество врачебных ошибок и ошибок самолечения, по нашему мнению, достигает критического
                        предела. Еще больше нас удручает то, что никто не пытается исправить эту ситуацию. Как ни
                        печально это признавать, но аптечный бизнес, как и любой другой бизнес, по большей части
                        ориентирован на получение прибыли. Но для нас очень важно, чтобы мы приносили пользу, а наш
                        ресурс помогал людям не только найти ответы на все их вопросы о здоровье, но и помогал
                        приблизиться к сознательности во всем, что касается здоровья, самочувствия, медицины и
                        применения лекарственных средств.
                    </p>

                    <p>
                        Поэтому мы видим своей миссией помощь людям в обретении сознательности и образованности в
                        сфере медицины и здоровья. Для реализации этой миссии мы оказываем такой сервис и
                        предоставляем такие знания, которые позволяют людям узнать больше о своем организме, о
                        физиологических и психических процессах, о взаимодействии лекарств, об их побочных эффектах
                        и причинах, почему они могут быть эффективны, чтобы каждый человек мог принять осознанное
                        решение касательно своего здоровья.
                    </p>`,
        targetTitle: `Как мы достигаем своей цели?`,
        targetBody: `<p>
                        Частным способом решения этих задач является наш блог о здоровье, в котором мы освещаем в
                        статьях самые актуальные вопросы, подробно, и, главное, доступно, рассказываем о том, что
                        происходит с человеческим организмом, и, конечно, даем понять каждому читателю, что наша
                        информация может принести спокойствие и понимание, но, конечно, не может заменить консультацию,
                        потому что только врач может поставить правильный диагноз.
                    </p>

                    <p>
                        Мы скрупулезно подходим к заполнению инструкций и описаний препаратов, подаем информацию очень
                        подробно и доступно для широких масс населения, чтобы каждый человек мог понять сложные
                        медицинские термины и разобраться в вопросах медицины. Именно это помогает нашим посетителям
                        принимать осознанные решения при лечении и покупке лекарств.
                    </p>

                    <p>
                        Реализуя сознательный подход к медицине и здоровью, мы даем нашим клиентам возможность
                        приобретать только лицензированные препараты и товары медицинского назначения, занимаемся
                        просветительской деятельностью, предупреждаем о побочных эффектах, передозировке и
                        взаимодействиях лекарственных средств, призываем не заниматься самолечением и предоставляем
                        информацию по вопросам здоровья, которая обязательно проходит  проверку не только нашей
                        редакцией, но и командой медицинских экспертов, в которую входят высококвалифицированные врачи
                        с многолетней практикой и которые искренне отстаивают интересы пациентов.
                    </p>`
    },
    editorialPolicy: {
        title: "Редакционная политика",
        content: `<div class="about-page__content">
                    <h4 class="content__title">
                        Содержание:
                    </h4>

                    <ol>
                        <li><a href="#spheres">Сферы регулирования редакционной политики</a></li>
                        <li><a href="#principles">Наши этические принципы</a></li>
                        <li><a href="#creation">Редакционный процесс создания контента</a></li>
                        <li><a href="#products">Редакционная политика медицинских товаров</a></li>
                        <li><a href="#blog">Редакционная политика статей блога</a></li>
                        <li><a href="#questions">Спорные вопросы</a></li>
                        <li><a href="#interests">Конфликт интересов</a></li>
                        <li><a href="#contacts">Сотрудничество и обратная связь</a></li>
                        <li><a href="#responsibility">Отказ от ответственности</a></li>
                    </ol>
                </div>

                <p>
                    Мы знаем, что доверие нужно заслужить, поэтому считаем необходимым рассказать, как электронная медицинская информационная система apteka24.ua создает точный, понятный, актуальный и доказательный контент.
                </p>

                <p>
                    Найти информацию о здоровье и благополучии в интернете очень просто — она есть везде. Но найти достоверную, актуальную и полезную информацию — сложно и даже утомительно. Электронная медицинская информационная система apteka24.ua все меняет. Мы делаем информацию о здоровье понятной и доступной, чтобы вы могли принимать оптимальные и медицински обоснованные решения касательно своего здоровья и здоровья своих близких.
                </p>

                <h2 id="spheres">Сферы регулирования редакционной политики</h2>

                <p>Существующая редакционная политика электронной медицинской информационной системы apteka24.ua регулирует следующие сферы деятельности и коммуникации веб-сайта <a target="_blank" href="${links.homePage}">apteka24.ua</a>&nbsp;(далее — Сайт) с клиентами:</p>

                <ul>
                    <li>главная страница Сайта;</li>
                    <li>навигация всех страниц Сайта;</li>
                    <li>каталог товаров;</li>
                    <li>карточки товаров (инструкция, характеристика, описание, подбор аналогов);</li>
                    <li>описание акций, программ лояльности, купонов и скидок;</li>
                    <li>статьи блога, обзоры, дайджесты, новостные объявления;</li>
                    <li>email-рассылка, всплывающие уведомления, информирование в чате.</li>
                </ul>

                <h2 id="principles">Наши этические принципы</h2>

                <p>
                    Принципы электронной медицинской информационной системы apteka24.ua, которыми мы руководствуемся в своей работе и которые организовывают и определяют все сферы регулирования редакционной политики, следующие:
                </p>

                <h4>№1 Достоверность</h4>

                <p>Мы тщательно следим за тем, чтобы вся информация на Сайте была актуальной и правдивой.&nbsp;
                    <a target="_blank" href="${links.medicalExperts}">
                        Редакторская команда медицинских экспертов
                    </a>, включающая фармацевтов компании и врачей-экспертов соответствующей специализации и компетенции, является цензорами Сайта, проверяет все инструкции и описания к препаратам, следит за правильностью подбора фото к товарам и достоверностью статей в блоге.
                </p>

                <h4>№2 Актуальность</h4>

                <p>На сайте размещена только актуальная информация, которая редактируется по мере ее поступления и изменений в официальных источниках. Инструкции к лекарственным средствам регулярно обновляются в зависимости от новых изменений, внесенных в&nbsp;
                    <a target="_blank" href="${links.stateRegisterOfDrugs}">Державний реєстр лікарських засобів України</a>,
                    либо на сайте производителя.
                </p>

                <h4>№3 Полезность</h4>

                <p>
                    Мы заботимся о том, чтобы вся информация, размещенная на Сайте, была полезной пользователям, просвещала, отвечала на вопросы и решала проблемы.
                </p>

                <h4>№4 Ответственность</h4>

                <p>
                    Электронная медицинская информационная система apteka24.ua несет полную ответственность за все материалы и информацию, опубликованные на Сайте. Мы гарантируем достоверность, релевантность, надежность и актуальность всех материалов, размещенных на Сайте.
                </p>

                <h4>№5 Научная обоснованность</h4>

                <p>
                    Мы не используем непроверенные факты и данные, а опираемся в своих материалах только на официальные и проверенные источники информации. Каждый тип контента проходит обязательную верификацию со стороны медицинского эксперта, входящего в редакторскую команду электронной медицинской информационной системы apteka24.ua.
                </p>

                <h4>№6 Источники информации</h4>

                <p>К источникам информации на сайте apteka24.ua относятся:</p>

                <p>
                    1. <strong>Официальный источник</strong> — надежный первоисточник, обладающий доказательными, научно и клинически подтвержденными, одобренными Всемирной организацией здравоохранения или Министерством здравоохранения Украины данными.
                </p>

                <p>К официальным источникам относятся:</p>

                <ul>
                    <li>
                        <a target="_blank" href="${links.worldHealthOrganization}">
                            Всемирная организация здравоохранения;
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="${links.ukraineMinistryHealth}">
                            Министерство здравоохранения Украины;
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="${links.lowOfUkraineAboutHealthProtection}">
                            Закон Украины «Основи законодавства України про охорону здоров&apos;я»;
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="${links.lowOfUkraineAboutDrugs}">
                            Закон Украины «Про лікарські засоби»;
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="${links.stateRegisterOfDrugs}">
                            Державний реєстр лікарських засобів України;
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.nlm.nih.gov/">
                            Национальная библиотека медицины США;
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/">
                            База данных Национального центра биотехнологической информации США;
                        </a>
                    </li>

                    <li>
                        <a target="_blank" href="https://www.cdc.gov/">
                            Центр по контролю и профилактике заболеваний;
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.fda.gov/">
                            Управление по санитарному надзору за качеством пищевых продуктов и медикаментов (FDA).
                        </a>
                    </li>
                </ul>

                <p>
                    2. <strong>Проверенный источник</strong> — первоисточник (научная статья, диссертация, обзор научного исследования),  обладающий убедительными, но не достаточно доверительными данными, поэтому нуждающийся в проверке на достоверность медицинским экспертом. Проходит обязательную верификационную проверку уполномоченным цензором с высшим медицинским или фармацевтическим образованием на соответствие фактов действительности и научной доказуемости.
                </p>
                <p>
                    Каждый вид контента на Сайте сопровождается статусом «Официальный источник» или «Проверенный источник».
                </p>
                <h4>№7 Грамотность и точность</h4>
                <p>
                    Над каждым текстом проводится редакторская работа, преследующая цель, чтобы все материалы на Сайте были опубликованы без ошибок и неуточненных деталей. Мы следим за орфографической, пунктуационной, грамматической, синтаксической, лингвистической и семантической грамотностью и точной подачей информации.
                </p>
                <h4>№8 Уникальность</h4>
                <p>
                    Мы не занимаемся плагиатом. Все наши статьи проходят проверку на уникальность и допускаются к публикации только в том случае, если уникальность материала достигает 95% и выше согласно сервису text.ru.
                </p>

                <h4>№9 Доступность</h4>
                <p>Вся информация, размещенная на Сайте электронной медицинской информационной системы apteka24.ua, должна быть доступной и понятной для широких масс населения. Не допускается использование двусмысленных, неуточненных и размытых слов, фраз и выражений, а также терминологии узкой специализации без разъяснения и уточнения смысловой и семантической направленности.</p>
                <p>Каждый тип контента обязан быть максимально полным и раскрывающим все вопросы по освещаемой теме.</p>

                <h4>№10 Законность</h4>
                <p>Весь контент Сайта обязан соответствовать <a target="_blank" href="${links.lowOfUkraineAboutCopyright}">Закону Украины «Об авторском праве и смежных правах»</a> и не нарушать авторское право первоисточников. Плагиат запрещен.</p>
                <p>Вся информация подлежит глубокой и тщательной переработке и дополнению. При дословном использовании информации из официального или проверенного источника указывается гиперссылка на первоисточник.</p>
                <p>Использование инструкции, характеристик и описаний препаратов, изъятых из <a target="_blank" href="${links.stateRegisterOfDrugs}">Державного реєстру лікарських засобів України</a>, обязательно сопровождается указанием знака копирайта © и наименования источника.</p>


                <h2 id="creation">Редакционный процесс создания контента</h2>
                <p>Мы говорим просто и доступно о сложных темах и избегаем использования медицинской и научной терминологии без раскрытия значения терминов доступным для понимания языком. Поэтому,  если вы ищете основанное на фактах руководство по конкретному заболеванию, по вопросам здоровья или общему самочувствию, мы здесь для вас.</p>

                <p>Весь наш контент создается для того, чтобы вы могли прийти к всеобщему благополучию, которое достигается благодаря гармоничной взаимосвязи психического и физического. Мы помогаем установить эту связь и изменить образ жизни. В нашем понимании, как и в <a target="_blank" href="${links.worldHealthOrganizationFaq}">понимании ВОЗ</a>&nbsp;<span class="text-green">[Официальный источник]</span>, это называется «всеобщим здоровьем человека». И чтобы следовать этому пути, мы открыто и объективно освещаем широкий круг тем и точек зрения на все сферы жизни человека.</p>

                <h4>Наша миссия</h4>

                <p>Наша цель — быть вашим самым надежным союзником в стремлении к достижению здоровья и благополучия. Мы хотим быть теми, кому вы можете доверять и кто вас не подведет. Поэтому в любом виде контента  мы предоставляем доказательства своей надежности.</p>

                <h4>Процесс создания контента</h4>

                <p>Редакция и медицинские эксперты электронной медицинской информационной системы apteka24.ua стремятся создавать качественный, актуальный, полезный и достоверный контент, придерживаясь самых высоких журналистских стандартов в области медицины и здоровья. Мы стремимся предоставить исчерпывающие, беспристрастные, честные и своевременные рекомендации и практические советы, новости и обзоры, руководства и инструкции.</p>

                <h4>Наша команда медицинских экспертов</h4>

                <p>Медицинский контент электронной медицинской информационной системы apteka24.ua создается на основании фактов, научных данных и официальных или проверенных источников, а также проверяется квалифицированными авторами, редакторами, врачами, фармацевтами и другими специалистами-консультантами <a target="_blank" href="${links.medicalExperts}">команды</a>, которые разделяют и поддерживают взгляды нашей редакции.</p>

                <p>Контент всеобщего здоровья и социокультурной сферы жизни создается с не меньшей щепетильностью, основывается на авторитетных мнениях и заключениях экспертов, однако может содержать неподтвержденные научным путем данные, так как области науки не в полной мере покрывают все сферы жизни человека и его интересы. В развлекательных категориях блога мы можем рассматривать эмпирически неподтвержденные, но распространенные и имеющие место быть актуальные темы для людей с различными точками зрений.</p>
                <h4>Наш процесс верификации контента</h4>

                <p>Сведения о лекарственных препаратах, товарах медицинского назначения, а также информация по вопросам здоровья и благополучия в блоге электронной медицинской информационной системы apteka24.ua проверяются с группой профессиональных медиков, фармацевтов или редакторов для обеспечения точности, достоверности, актуальности и релевантности.</p>

                <p>На нашу команду медицинских цензоров можно положиться — это  специалисты широкого спектра медицинских отраслей, у которых за плечами большой опыт клинической практики, исследований и защиты интересов пациентов.</p>

                <h4>Наши авторы</h4>

                <p>Электронная медицинская информационная система apteka24.ua тщательно оценивает всех потенциальных авторов статей о здоровье и благополучии. Прежде, чем мы опубликуем статью, мы обязательно должны убедиться, что авторы контента являются специалистами в предметной области, освещаемой в контенте, а также, что у них есть соответствующий жизненный опыт.</p>

                <p><a target="_blank" href="${links.team}">Авторов редакции</a> мы обучаем передовым методам ведения исследовательской журналистской деятельности, поиску лидеров мнений и профессиональных экспертов, а также регулярно предоставляем им обратную связь и беспрерывное обучение.</p>

                <h4>Наши ссылки, источники и цитаты</h4>

                <p>У нас строгие правила выбора первоисточников, так как мы полагаемся на рецензируемые исследования, академические исследовательские институты, правительственные учреждения и медицинские ассоциации. Список источников, информация в которых считается официальными неопровержимыми данными, прописан <a target="_blank" href="${links.editorialPolicy}">в нашей редакционной политике</a>.</p>

                <p>Каждая статья тщательно исследуется, а источники проверяются на предмет актуальности, достоверности и объективности.</p>

                <p>Первоисточники, включая исследования, научные ссылки и статистические данные, указываются активными ссылками в каждой статье.</p>

                <h4>Наши обновления и актуализация</h4>

                <p>Редакция электронной медицинской информационной системы apteka24.ua постоянно следит за изменениями в медицинской, научной и социокультурной сферах. При появлении новой  информации мы обновляем статьи.</p>

                <p>У нас есть установленный график обслуживания статей, подразумевающий ежегодное обновление контента, однако обновления могут быть проведены заранее в таких случаях:</p>

                <ul>
                    <li>появление новой доказанной информации, опровергающей предыдущее положение;</li>
                    <li>изменения в стандартах лечения и ухода за больными;</li>
                    <li>новые клинические рекомендации;</li>
                    <li>выход на рынок или отзыв лекарственных средств;</li>
                    <li>изменение важных практических рекомендаций;</li>
                    <li>обращение читателей, аргументирующее просьбу об изменении неактуального или недостоверного контента.</li>
                </ul>

                <p>Читатель может обратиться к  нам с предупреждением о потенциальной проблеме с нашим контентом, например, о неточной, устаревшей, нечеткой или противоречивой информации. В таком случае, мы реагируем незамедлительно и принимаем меры.</p>
                <p>Редакция и команда медицинских экспертов изучают полученные отзывы, определяют, какие изменения необходимо внести в контент, и повторно публикуют обновленную информацию.</p>


                <h4>Наш tone of voice</h4>

                <p>Мы говорим понятно и доступно, тепло и по-домашнему, но при этом наш голос звучит смело и прогрессивно.</p>

                <p>Основной акцент в своей речи мы делаем на простоте, точности, эмпатии и инклюзивности. Позитивность подачи информации играет важную роль, так как мы хотим вселять надежду и дарить радость, а не зацикливать читателей на негативе. Мы используем позитивный язык, который стимулирует к активным действиям для изменения своего образа жизни в лучшую сторону.</p>

                <p>На всех уровнях коммуникации с читателями и клиентами мы придерживаемся сознательности и стараемся доносить информацию максимально доступно и понятно для любого человека.</p>
                <h4>Мы рады узнать ваше мнение</h4>

                <p>Мы полны решимости предоставлять вам только самые положительные впечатления и полезную информацию. Поэтому мы будем рады получить от вас обратную связь с отзывами, вопросами и замечаниями, ведь ваше мнение поможет нам добиться большего.</p>




                <h2 id="products">Редакционная политика медицинских товаров</h2>
                <p>
                    Карточки товаров на Сайте содержат официальную инструкцию, характеристики товара, описание и аналоги. Вся информация соответствует официальным источникам и проверяется уполномоченным медицинским цензором.
                </p>

                <h4>№1 Инструкция</h4>
                <p>
                    Инструкции лекарственных средств и препаратов могут быть взяты только из официального источника <a target="_blank" href="${links.stateRegisterOfDrugs}">Державний реєстр лікарських засобів України</a>. Описания товаров медицинского назначения обязаны соответствовать официальному источнику, которым является сайт производителя.
                </p>
                <p>
                    Инструкция заполняется исключительно на украинском языке и обязана содержать следующие пункты:
                </p>

                <ul>
                    <li>состав;</li>
                    <li>лекарственная форма;</li>
                    <li>основные физико-химические свойства;</li>
                    <li>фармакотерапевтическая группа;</li>
                    <li>фармакологические свойства;</li>
                    <li>показания;</li>
                    <li>противопоказания;</li>
                    <li>взаимодействие с другими лекарственными средствами и другие виды взаимодействий;</li>
                    <li>особенности применения;</li>
                    <li>способность влиять на скорость реакции при управлении транспортом и сложными механизмами;</li>
                    <li>способ применения и дозы;</li>
                    <li>передозировка;</li>
                    <li>побочные реакции;</li>
                    <li>срок годности;</li>
                    <li>условия хранения;</li>
                    <li>упаковка;</li>
                    <li>категория отпуска;</li>
                    <li>производитель;</li>
                    <li>месторасположение производителя.</li>
                </ul>

                <h4>№2 Характеристики</h4>
                <p>
                    Характеристики товаров заполняются в соответствии с официальным источником (<a target="_blank" href="${links.stateRegisterOfDrugs}">Державний реєстр лікарських засобів України</a>), являются кратким отображением основных характеристик препарата, проверяются медицинским цензором на соответствие действительности и содержат следующие пункты:
                </p>

                <ul>
                    <li>АТС-Классификация;</li>
                    <li>рецептурный отпуск;</li>
                    <li>срок годности;</li>
                    <li>годен до;</li>
                    <li>дозировка;</li>
                    <li>упаковка;</li>
                    <li>состав;</li>
                    <li>показания;</li>
                    <li>противопоказания;</li>
                    <li>код Морион;</li>
                    <li>фармакотерапевтическая группа;</li>
                    <li>действующее вещество;</li>
                    <li>украинское название.</li>
                </ul>

                <h4>№3 Описание</h4>
                <p>
                    Описание лекарственного средства или препарата основывается на официальной инструкции (<a target="_blank" href="${links.stateRegisterOfDrugs}">Державний реєстр лікарських засобів України</a>) или маркетинговом материале с официального сайта производителя и является доступным для пациента информированием о важных свойствах, взаимодействиях, клинических характеристиках и особенностях лекарственного средства. Проходит проверку медицинским цензором на соответствие действительности.
                </p>

                <h4>№4 Алгоритм подбора аналогов лекарственного средства</h4>
                <p>Карточка товаров каждого препарата содержит его аналоги. Подбор аналогов лекарственного средства на сайте электронной медицинской информационной системы Apteka24.ua происходит по алгоритму, утвержденному <a target="_blank" href="https://www.whocc.no/">Сотрудничающим центром ВОЗ по методологии статистики лекарственных средств</a> и принятому Министерством здравоохранения Украины:</p>

                <ol>
                    <li>Первый уровень: аналогичные лекарственные средства с тем же действующим веществом, дозировкой и формой выпуска.</li>
                    <li>Второй уровень: аналогичные лекарственные средства с тем же действующим веществом, дозировкой, но с другой формой выпуска.</li>
                    <li>Третий уровень: аналогичные лекарственные средства с тем же действующим веществом, но  с другой дозировкой и другой формой выпуска.</li>
                    <li>Четвертый уровень: аналогичные лекарственные средства, подобранные согласно АТС-классификации, которые могут содержать другое действующее вещество, другую дозировку и другую форму выпуска, но оказывают схожий клинический эффект и могут применяться  для  лечения того же заболевания или имеют влияние на ту же систему органов или органы.</li>
                </ol>

                <p>Точность следования алгоритму проверена медицинским цензором.</p>

                <h4>№5 Основные физико-химические свойства</h4>
                <p>Карточка товара обязана содержать описание физико-химических свойств препарата.</p>

                <p>Данный раздел отражает классификацию лекарственных средств в зависимости от температурного режима хранения с указанием диапазона допустимых температур, не нарушающих физико-химические свойства препарата, а также форму выпуска, объем, количество в упаковке и данные о производителе.</p>

                <p>Информация соответствует Державному реєстру лікарських засобів України и официальному сайту производителя, а также проверяется уполномоченным медицинским цензором на соответствие действительности.</p>

                <h4>№6 Сертификат</h4>
                <p>Карточка товара лекарственного средства содержит ссылку на скачивание сертификата качества.</p>

                <p>Сертификат качества является гарантом подлинности лекарственного препарата и утверждает соответствие качественных характеристик лекарственного средства его свойствам, заявленным в инструкции. Сертификат качества выдается препарату только после экспертизы <a target="_blank" href="${links.stateServiceOfDrugsControl}">Государственной службы Украины по лекарственным средствам и контролю за наркотиками</a> и присуждения препарату сертификата GMP. Сертификат качества соответствует требованиям украинского законодательства, утвержденному регистрационному свидетельству, спецификации МКК (методы контроля качества) и сертификату соответствия производства лекарственных средств согласно требованиям GMP, а также проходит проверку медицинским цензором электронной медицинской информационной системы Apteka24.ua на соответствие действительности.</p>

                <h4>№7 Верификация</h4>

                <p>Каждый информационный блок в карточках товаров проходит верификацию на релевантность информации, ее достоверность и научную доказуемость. Верификацию проводит уполномоченный медицинский цензор, состоящий в <a target="_blank" href="${links.medicalExperts}">редакционной команде медицинских экспертов</a> электронной медицинской информационной системы Apteka24.ua.</p>
                <p>Инструкции должны соответствовать официальному источнику, которым является <a target="_blank" href="${links.stateRegisterOfDrugs}">Державний реєстр лікарських засобів України</a>, быть точными и достоверными официальным данным.</p>
                <p>В каждой карточке товара указывается медицинский эксперт-цензор, проверивший и одобривший ее, а также статус контента «Проверено», при наведении курсора на который всплывает подсказка, раскрывающая суть статуса.</p>

                <p>
                    Подсказка статуса «Проверено» содержит следующую информацию:
                    <i>
                        «Проверено. Вся указанная информация соответствуют официальному источнику и достоверным данным, проверена и одобрена уполномоченным цензором на соответствие действительности. Для того, чтобы ознакомиться с редакционной политикой электронной медицинской информационной системы Apteka24.ua нажмите на «Проверено».
                    </i>
                </p>

                <p>
                    Каждая инструкция содержит знак копирайта © с указанием  официального источника (<a target="_blank" href="${links.stateRegisterOfDrugs}">Державний реєстр лікарських засобів України</a>) и значок подсказки с уведомлением о верификации инструкции. Подсказка содержит следующий текст:
                </p>

                <p>
                    <i>
                        Данная инструкция соответствует действительности и является достоверной. Официальный источник: <a target="_blank" href="${links.stateRegisterOfDrugs}">Державний реєстр лікарських засобів України</a>&nbsp;<span class="text-green">[Официальный источник]</span>. Публикация инструкции и описания осуществлена на основании <a target="_blank" href="${links.editorialPolicy}">редакционной политики</a> электронной медицинской информационной системы Apteka24.ua, соответствует «<a target="_blank" href="${links.lowOfUkraineAboutDrugs}">Закону Украины «Про лікарські засоби</a>» <span class="text-green">[Официальный источник]</span> и «<a target="_blank" href="${links.lowOfUkraineAboutCopyright}">Закону Украины «Об авторском праве и смежных правах</a>» <span class="text-green">[Официальный источник]</span>, а также проверена уполномоченным цензором. Обновление инструкции на сайте apteka24.ua осуществляется после ее обновления на сайте Государственного реєстра лекарственных средств Украины, либо на официальном сайте производителя.
                    </i>
                </p>

                <h4>№8 Обновление</h4>

                <p>
                    Не реже чем раз в год каждый информационный блок карточек товаров (инструкция, характеристика, описание) должен подлежать пересмотру на предмет соответствия обновлениям в официальном источнике <a target="_blank" href="http://www.drlz.com.ua/">Державний реєстр лікарських засобів України</a>. При выявлении обновлений в официальном источнике, данные обновления должны быть внесены в карточку товара на Сайте.
                </p>
                <p>
                    Каждое обновление карточек товара подлежит верификации со стороны уполномоченного медицинского цензора.
                </p>
                <h4>№9 Источники информации</h4>
                <p>К источникам информации карточек товаров относятся только официальные источники:</p>

                <ul>
                    <li>
                        <a target="_blank" href="${links.stateRegisterOfDrugs}">
                            Державний реєстр лікарських засобів України
                        </a>;
                    </li>
                    <li>сайт производителя товаров.</li>
                </ul>



                <h2 id="blog">Редакционная политика статей блога</h2>

                <h4>№1  Направленность блога</h4>
                <p>
                    Блог электронной медицинской информационной системы Apteka24.ua является обширным информационным ресурсом, включающим рубрики как научно-медицинской направленности, так и развлекательного характера:
                </p>
                <ul>
                    <li>Здоровье</li>
                    <li>Lifestyle</li>
                    <li>Красота</li>
                    <li>Психология</li>
                    <li>Развлечения</li>
                    <li>Путешествия</li>
                    <li>Foodstyle</li>
                    <li>How-To</li>
                    <li>Актуальное в мире</li>
                    <li>Наши новости</li>
                </ul>

                <p>
                    Блог электронной медицинской информационной системы Apteka24.ua предназначен для информирования широких масс населения по вопросам здоровья в глобальном смысле и не преследует цель продажи лекарственных средства и товаров медицинского назначения, размещенных на Сайте. Упоминание торговых наименований не рекомендуется к использованию в статьях блога. Если же торговые наименования упоминаются, то лишь в ознакомительных целях.
                </p>

                <h4>№2 Стиль</h4>

                <p>
                    В статьях блога общеобразовательного и информационного характера необходимо придерживаться <strong>публицистического стиля</strong>. Допускается использование юмористических, разговорных и метафорических оборотов там, где необходимо сделать акцент на эмоциональной составляющей или преследуется цель побудительного действия.
                </p>

                <p>
                    В статьях медицинской тематики, при описании препаратов, заболеваний и их лечения
                    используется <strong>научный (академический) стиль</strong>.
                </p>

                <h4>№3 Структура статьи</h4>
                <p>В статью должны входить следующие <strong>структурные элементы:</strong></p>

                <ol>
                    <li>Заголовок Н1.</li>
                    <li>Краткое вступление, посвященное теме статьи и не содержащее незначимых и неинформативных фраз.
                    </li>
                    <li>Подзаголовки Н2, разделяющие статью на тематически обособленные блоки, логически связанные между
                        собой.
                    </li>
                    <li>При необходимости могут использоваться подзаголовки Н3.</li>
                    <li>Использование нумерованных (сложных) и маркированных (простых) списков там, где это возможно и
                        необходимо.
                    </li>
                    <li>Внедрение в текст статьи визуального контента в виде тематически подобранных иллюстраций и
                        равномерно распределенных по тексту.
                    </li>
                    <li>В нижней части статьи сообщается о других статьях на похожую тематику с указанием ссылок на
                        данные материалы.
                    </li>
                    <li>Заключение, содержащее краткий вывод по теме статьи и резюмирующее весь объем представленного
                        материала.
                    </li>
                    <li>Заключительная фраза: «Электронная медицинская информационная система Apteka24.ua желает вам здоровья!» Может использоваться в разных
                        вариациях в зависимости от темы статьи.
                    </li>
                </ol>

                <h4>№4 Правила составления списков</h4>
                <p>Статья может содержать два вида списков.</p>

                <p>Нумерованный (пример):</p>
                <ol>
                    <li>Список.</li>
                    <li>Список.</li>
                    <li>Список.</li>
                </ol>

                <p>Маркированный (пример):</p>
                <ul>
                    <li>список;</li>
                    <li>список;</li>
                    <li>список.</li>
                </ul>

                <p><strong>Правила оформления нумерованного списка:</strong></p>
                <ol>
                    <li>Нумерованный список — это сложный список. Он содержит расширенное предложение или несколько
                        предложений.
                    </li>
                    <li>Первая буква в каждом новом пункте списка — прописная (большая).</li>
                    <li>В конце каждого пункта ставится точка.</li>
                </ol>
                <p><strong>Правила оформления маркированного списка:</strong></p>
                <ul>
                    <li>простой список;</li>
                    <li>пункты содержат словосочетание или слово;</li>
                    <li>первая буква — строчная (маленькая);</li>
                    <li>в конце ставится точка с запятой;</li>
                </ul>

                <h4>№5 Слова, предложения, абзацы</h4>

                <ol>
                    <li>Недопустимо использование ненормативной лексики и грубых слов и выражений.</li>
                    <li>Обращение к читателю — на «вы» со строчной буквы.</li>
                    <li>Позиционирование автора в тексте статьи — от первого лица, используя местоимение «мы».</li>
                    <li>Написание названия интернет-аптеки допускается следующим образом: Электронная медицинская информационная система Apteka24.ua и
                        apteka24.ua (в случае, если речь идет об отсылке на веб-сайт).
                    </li>
                    <li>Предложения лучше использовать короткие и понятные, без внедрения сложных конструкций.</li>
                    <li>Каждый абзац должен быть не более 700 символов. Небольшие абзацы более читабельны.</li>
                </ol>

                <h4>№6 Параметры текста</h4>
                <p>Каждый текст проходит проверку через сервис <a target="_blank" href="https://text.ru/">text.ru</a>.
                    Согласно этому сервису параметры текста должны быть следующими:
                </p>

                <ul>
                    <li>заспамленность — не более 55%;</li>
                    <li>вода — не более 16-17%;</li>
                    <li>уникальность — от 95% и выше.</li>
                </ul>

                <p>Также статьи проходят проверку по сервису «<a target="_blank" href="https://glvrd.ru/">Главред</a>»,
                    где должна быть достигнута оценка не менее 7 баллов.
                </p>

                <h4>№7 Сопроводительный визуальный контент</h4>

                <p>Автор статьи подбирает иллюстрации под тему написанного материала.</p>

                <p>Изображения можно брать из свободных стоковых фотобанков или использовать собственные уникальные изображения.</p>

                <p>Список фотобанков:</p>

                <ul>
                    <li><a target="_blank" href="https://pixabay.com/ru/">Pixabay</a>;</li>
                    <li><a target="_blank" href="https://unsplash.com/">Unsplash</a>;</li>
                    <li><a target="_blank" href="https://www.pexels.com/ru-ru/">Pexels</a>;</li>
                    <li><a target="_blank" href="https://ru.freeimages.com/">Freeimages</a>.</li>
                </ul>

                <p>Иллюстрации для контента в некоторых случаях могут создаваться непосредственно под статью штатным
                    дизайнеров компании.
                </p>

                <p><strong>Недопустимо использование изображений следующего характера:</strong></p>

                <ul>
                    <li>эротика и порнография;</li>
                    <li>сцены насилия;</li>
                    <li>предметы интимного назначения;</li>
                    <li>обнаженные интимные части тела (даже у младенцев);</li>
                    <li>люди со множеством татуировок;</li>
                    <li>шприцы;</li>
                    <li>обилие таблеток;</li>
                    <li>наркотические вещества.</li>
                </ul>

                <p>Проверка на допустимость использования изображения осуществляется через&nbsp;
                    <a
                        target="_blank"
                        href="https://cloud.google.com/vision/?fbclid=IwAR0jMzojgLKY3w9s_O0L8BRS9n4hAKq_L5muJvNfkgz12AqazuL-a61fQRQ"
                    >
                        сервис проверки изображений
                    </a>. Допускается использовать иллюстрации, у которых во вкладке «Safe Search»
                    отмечено не более 2-х пунктов по каждому перечисленному параметру.
                </p>

                <p>
                    В целях соблюдения <a target="_blank" href="${links.lowOfUkraineAboutCopyright}">Закона  Украины «Об авторском праве и смежных правах</a> запрещается использование на Сайте авторских изображений без заключения письменного авторского договора о передаче исключительных или неисключительных авторских прав.
                </p>

                <h4>№8 Использование ссылок на сторонние ресурсы</h4>

                <p>Редакция электронной медицинской информационной системы Apteka24.ua в статьях блога в обязательном порядке указывает ссылки на сторонние информационные  ресурсы, являющиеся официальными или проверенными источниками, подтверждающими достоверность поданной в материале информации. Однако мы не несем ответственность за информацию, поданную на этих ресурсах, и не отвечаем за ее достоверность, точность, надежность и релевантность.</p>

                <p>Редакция может выбирать определенные сайты в качестве приоритетных ответов на вводимые вами поисковые запросы, а также может разрешить рекламодателям отвечать на определенные поисковые запросы рекламой или спонсируемым контентом.</p>

                <p>При этом важно понимать, что Электронная медицинская информационная система Apteka24.ua не рекомендует и не поддерживает контент на каких-либо сторонних веб-сайтах. Мы не несем ответственность за содержание указанных в наших материалах сторонних веб-сайтов и сайтов, созданных в рамках рекламы товаров или услуг, сторонних сайтов, представленных в качестве результатов поиска, или внешних рекламных объявлений. Мы не даем никаких гарантий относительно их содержания или точности. Если вы переходите или используете сторонние веб-сайты, то делаете это на свой страх и риск, несете личную ответственность за использование стороннего веб-сайта и пользуетесь им в соответствии с условиями использования данного сайта.</p>

                <p>Электронная медицинская информационная система Apteka24.ua не поддерживает какие-либо товары, рекламируемые в контенте сторонних веб-сайтов.</p>

                <p>Вы можете узнать подробнее о <a target="_blank" href="${links.marketingPolicy}">маркетинговой политике электронной медицинской информационной системы Apteka24.ua</a>.</p>

                <h4>№9 Проверка текста</h4>
                <p>Каждый автор статей несет ответственность за написанный текст.</p>

                <p>
                    После написания материал нужно вычитать и проверить на наличие грамматических, синтаксических, орфографических, семантических и пунктуационных ошибок.
                </p>

                <p>В дальнейшем статья отправляется на проверку главному редактору.</p>

                <p>
                    После проверки главного редактора статьи на медицинскую тематику проверяются на достоверность членами редакционной команды медицинских экспертов соответствующей компетенции и специализации.
                </p>

                <h4>№10 Верификация текста</h4>
                <p>
                    Каждая статья блога подлежит верификации со стороны уполномоченного медицинского эксперта, входящего в редакционную команду медицинских экспертов электронной медицинской информационной системы Apteka24.ua.
                </p>
                <p>
                    Статьи блога должны быть достоверными, надежными, содержащими только доказательные данные и сопровождающимися ссылками на первоисточники (официальные и проверенные источники).
                </p>

                <p>В каждой статье указывается автор статьи и медицинский эксперт-цензор.</p>

                <p>
                    Каждая статья сопровождается статусом «Проверено» и значком со всплывающей подсказкой о верификации, содержащей следующее уведомление:
                </p>

                <p>
                    <i>
                        Данная информационная статья соответствует действительности, является достоверной и опирается на официальные источники. Публикация статьи осуществлена на основании редакционной политики электронной медицинской информационной системы Apteka24.ua, соответствует Закону Украины «<a target="_blank" href="${links.lowOfUkraineAboutDrugs}">Про лікарські засоби</a>» <span class="text-green">[Официальный источник]</span> и <a target="_blank" href="${links.lowOfUkraineAboutCopyright}">Закону Украины «Об авторском праве и смежных правах»</a> <span class="text-green">[Официальный источник]</span>, а также проверена уполномоченным цензором. Обновление материалов на сайте apteka24.ua осуществляется по мере осуществления новых открытий в области медицины и науки.
                    </i>
                </p>

                <h4>№11 Гарантии</h4>
                <p>
                    Электронная медицинская информационная система Apteka24.ua гарантирует уникальность публикуемого контента, его актуальность, полезность и информационную наполненность.
                </p>

                <p>Каждая статья, инструкция и описание товаров проходит три этапа проверки:</p>

                <ol>
                    <li>Проверка автором.</li>
                    <li>Проверка главным редактором.</li>
                    <li>Проверка медицинским экспертом соответствующей специальности.</li>
                </ol>

                <p>Инструкции на сайте соответствуют&nbsp;
                    <a target="_blank" href="${links.stateRegisterOfDrugs}">
                        Державному реєстру лікарських засобів України
                    </a>и редактируются в зависимости от актуальных изменений в реестре.
                </p>

                <p>
                    Каждая статья обязана соответствовать нормам морали, нравственности, общественной полезности и не нарушать <a target="_blank" href="${links.lowOfUkraineAboutCopyright}">Закон Украины «Об авторском праве и смежных правах»</a>.
                </p>



                <h2 id="questions">Спорные вопросы</h2>

                <p>Любая медицинская и научная информация, подаваемая в статьях блога в рубрике «Здоровье», не может содержать неподтвержденную информацию, противоречащую официальным научным данным.</p>

                <p>В том случае, если на настоящий момент времени нет основательных и однозначно доказательных данных, если тема противоречива и мнения медицинских экспертов и ученых расходятся по освещенному поводу, то необходимо в материале указать спорность данного вопроса. Такая статья должна содержать отказ от ответственности со стороны редакции с объяснением недостаточной доказуемости вопроса.</p>

                <p>Блог электронной медицинской информационной системы Apteka24.ua включает не только медицинские темы, но и темы развлекательного характера. В таком случае информация, содержащаяся в ненаучных статьях, носит лишь ознакомительный характер, может не совпадать с мнением редакции и не преследует цель оскорбить читателя с другой точкой зрения. Развлекательная информация не претендует на истинность и обоснованность и может являться лишь одним из бытующих в мире мнений и идеологий.</p>


                <h2 id="interests">
                    Конфликт интересов
                </h2>

                <p>Электронная медицинская информационная система Apteka24.ua сотрудничает с медицинскими экспертами — врачами, психологами, фармацевтами и другими представителями из области здоровья и благополучия. Такое сотрудничество в  некоторых аспектах может подразумевать конфликт интересов.</p>

                <p>Конфликт интересов может заключаться в том, что некоторые специалисты (фармацевты) являются сотрудниками электронной медицинской информационной системы Apteka24.ua и получают денежное вознаграждение за свою работу, что может  сказываться на их позиционировании и подаче информации в контенте Сайта.</p>
                <p>Электронная медицинская информационная система Apteka24.ua не несет ответственность за личное мнение и позиционирование каждого специалиста, однако старается минимизировать заведомо неправдоподобную либо не полезную информацию для посетителей Сайта, отказываясь от публикаций подобных статей.</p>

                <p>Публикуемый контент должен быть полезным для здоровья и благополучия читателей и не причинять вред их физическому и ментальному здоровью.</p>

                <p>Врачи и другие специалисты из области здоровья и благополучия сотрудничают с Электронной медицинской информационной системой Apteka24.ua на благотворительных началах и не получают за свою деятельность финансовое вознаграждение, что не исключает, но значительно снижает конфликт интересов.</p>

                <p>Если вы видите на Сайте неправдоподобный, устаревший либо ошибочный контент, обратитесь к редакции с сообщением, и мы примем  незамедлительные меры по устранению неточностей на Сайте.</p>



                <h2 id="contacts">Сотрудничество и обратная связь</h2>

                <p>
                    Электронная медицинская информационная система Apteka24.ua предлагает сотрудничество медицинским экспертам на правах рекламы — фармацевтам и врачам различных специализаций. Эксперты, желающие сотрудничать, могут стать частью <a target="_blank" href="${links.medicalExperts}">редакторской команды медицинских экспертов электронной медицинской информационной системы Apteka24.ua</a>.
                </p>

                <p>
                    Деятельность медицинских экспертов включает три направления:
                </p>

                <ul>
                    <li>вычитка, проверка, внесение правок и одобрение текстов, написанных авторами редакции Сайта;</li>
                    <li>написание собственных авторских материалов с их последующей публикацией на блоге Сайта;</li>
                    <li>интервьюирование по наиболее актуальным и важным вопросам для читателей Сайта.</li>
                </ul>

                <p>
                    Каждый вид деятельности при публикации сопровождается указанием медицинского эксперта в теле статьи с размещением его фотографии, ФИО, специализации, категории, места работы и гиперссылки на личный профиль или профиль медицинской клиники.
                </p>

                <p>
                    Для договоренности о сотрудничестве необходимо написать на почту главному редактору электронной медицинской информационной системы Apteka24.ua Юлии Щербаченко — <a href="mailto:editor@apteka24.ua">editor@apteka24.ua</a>.
                </p>

                <p>
                    Для получения обратной связи по материалам Сайта необходимо обратиться по вышеуказанному почтовому адресу.
                </p>



                <h2 id="responsibility">Отказ от  ответственности</h2>

                <p>В  материалах,  опубликованных на Сайте и включающих как инструкции, так и статьи блога, мы стараемся отразить самую достоверную информацию, чтобы максимально подробно и точно ответить на вопросы посетителей. Мы делаем все возможное, чтобы предоставлять научно доказанные и надежные данные, однако мы не даем никаких гарантий касательно нашего контента и не несем ответственность за его дальнейшее использование посетителями Сайта.</p>

                <p>Помните, что информация, опубликованная на Сайте, не является руководством к действию и носит сугубо ознакомительный характер. Самолечение может быть опасным для вашего здоровья, и если вы  решаетесь лечиться без консультации врача, то это решение на ваш страх и риск.</p>

                <p>Информация, которую мы предоставляем на apteka24.ua, не предназначена для замены консультации с квалифицированным медицинским работником — даже самое полное информирование не является поводом для отмены посещения врача. По всем медицинским вопросам, для консультации, диагностирования и назначения лечения необходимо обращаться к вашему лечащему врачу.</p>

                <p>Предоставляемый нами контент не предназначен для использования в целях диагностики или лечения. Сообщите своему врачу о любых изменениях  в своем самочувствии или о том, как вы собираетесь изменить свой образ жизни. Не пренебрегайте медицинскими советами и не откладывайте посещение поликлиники из-за того, что вы прочли на нашем сайте, в приложении или по другим каналам связи полезную для вас информацию.</p>

                <p>Если у вас возникнут вопросы или опасения по поводу каких-либо заболеваний, пожалуйста, обратитесь к своему врачу. Сайт apteka24.ua может ознакомить вас с вопросами здоровья, заболеваний и лечения, но он не заменит диагностику и консультацию специалиста.</p>

                <p>Редакция электронной медицинской информационной системы Apteka24.ua не несет ответственность за нецелесообразное использование информации посетителями Сайта.</p>`
    },
    marketingPolicy: {
        title: "Маркетинговая политика",
        headerContent: `<p>
                        Если вы здесь, значит, вы на шаг ближе к здоровью и благополучию. А мы в электронная медицинская информационная система apteka24.ua
                        делаем все возможное, чтобы помочь вам в этом: подбираем точные инструкции, пишем информативные
                        статьи и размещаем рекламные баннера, акции, информационные бюллетени и подкасты только с
                        полезной для вас информацией.
                    </p>

                    <p>
                        Поскольку люди обращаются к нам с уникальными проблемами, с личными историями и вопросами, мы
                        очень внимательно к ним прислушиваемся и ставим интересы здоровья и благополучия человека на
                        первое место в нашей компании.
                    </p>

                    <p>
                        Как раз из такого мировоззрения и идеи проистекает наш подход к рекламе на сайте apteka24.ua,
                        который соответствует, поддерживает и дополняет
                        <a href="${links.editorialPolicy}" target="_blank">
                            редакционную политику электронной медицинской информационной системы apteka24.ua.
                        </a>
                    </p>`,
        advertising: {
            title: `Рекламная политика`,
            description: `<p>
                    Электронная медицинская информационная система apteka24.ua получает финансирование от рекламы и спонсируемого контента.
                    Реальность такова, что финансирование от наших рекламодателей позволяет нам
                    покрывать большее количество затрат на создание качественного контента и
                    предоставлять более эффективный сервис.
                </p>

                <p>
                    Будьте уверены, мы абсолютно уверены в своей редакционной автономии. Мы никогда не
                    позволяем спонсорам влиять на создаваемый нами контент и оказываемые услуги.
                </p>

                <p>
                    У нас есть особые правила по рекламе и спонсорству, которым мы следуем, чтобы реклама
                    никогда не мешала редакционной честности и надежности.
                </p>

                <p>
                    Вся  реклама, которую вы видите на сайте apteka24.ua, соответствует принципу полезности
                    и обоснованности. Мы сами выбираем рекламу, не позволяем нам навязывать точку зрения рекламодателя
                    и никогда не  станем рекламировать то, что может потенциально  навредить, является  неоправданно
                    дорогим или не несет доказательной пользы. Мы четко различаем спонсируемый и не спонсируемый
                    контент и не допускаем пересечения одного с другим.
                </p>

                <p>
                    В некоторых случаях электронная медицинская информационная система apteka24.ua может создавать совместный или брендированный контент в
                    сотрудничестве с нашими спонсорами и/или представлять контент, созданный нашими спонсорами.
                    Однако мы никогда не противоречим идее полезности и благополучия.
                </p>

                <p>
                    В своей  рекламной политике мы следуем четким правилам:
                </p>`,
            items: `<div class="advertising__item">
                    <h4 class="item__title">
                        №1 Проверка рекламных объявлений
                    </h4>

                    <p>
                        Мы по собственному усмотрению определяем типы рекламы, которые будут отображены на ресурсах
                        электронной медицинской информационной системы apteka24.ua. Мы оставляем за собой право отклонить, отменить или удалить любую
                        рекламу в любое время и по любой причине, которую сочтем достаточно веской для подобного
                        действия. Одной из причин для удаления рекламы может стать жалоба на объявление, потенциальный
                        риск возможного вреда, отсутствие явной пользы.
                    </p>

                    <p>
                        Мы незамедлительно уведомим рекламодателя об отклонении, отмене или удалении любого объявления
                        вместе с объяснением. Мы также оставляем за собой право определять подходящее размещение
                        рекламы на ресурсах электронная медицинская информационная система apteka24.ua.
                    </p>
                </div>

                <div class="advertising__item">
                    <h4 class="item__title">
                        №2  Отказ от поддержки рекламируемых товаров или услуг
                    </h4>

                    <p>
                        Мы не поддерживаем рекламируемые на нашем ресурсе товары или услуги. Хотя некоторая продукция
                        может появляться на нашем сайте в формате рекламы, ни при каких обстоятельствах это не может
                        рассматриваться как одобрение рекламируемых товаров, услуг или бренда, который производит,
                        распространяет или продвигает эти товары и услуги.
                    </p>

                    <p>
                        В некоторых случаях электронная медицинская информационная система apteka24.ua может оценивать определенные товары или услуги и
                        составлять по ним  рейтинги. Эти рейтинги выстраиваются исключительно редакционной командой
                        без учета каких-либо финансовых или рекламных отношений, которые могут существовать с
                        компаниями, предоставляющими товары или услуги.
                    </p>
                </div>

                <div class="advertising__item">
                    <h4 class="item__title">
                        №3 Фильтрация рекламного контента
                    </h4>

                    <p>
                        Мы тщательно отбираем отображаемую на нашем ресурсе рекламу и не
                        принимаем определенные виды рекламы.
                    </p>

                    <p>
                        Мы не принимаем рекламу, которая является неточной, противоречащей здравому смыслу
                        или несущей потенциальный вред.
                    </p>

                    <p>
                        Фармацевтическая реклама разрешена только для утвержденных медикаментов и товаров
                        медицинского назначения, определенных <a href="${links.ukraineMinistryHealth}" target="_blank">Министерством здравоохранения Украины </a>
                        и <a href="${links.stateRegisterOfDrugs}" target="_blank">Государственным реестром лекарственных средств Украины. </a>
                        При этом рекламируемые товары должны присутствовать на рынке и быть легко доступными.
                    </p>

                    <p>
                        Электронная медицинская информационная система apteka24.ua ни в коем случае не допускает размещение рекламы вредных, незаконных или
                        нежелательных товаров и услуг. Объявления не должны содержать мошеннических, вводящих в
                        заблуждение или оскорбительных материалов, включая материалы, которые искажают, высмеивают или
                        угрожают отдельному человеку или группе лиц, противореча принципу инклюзивности на основании
                        возраста, цвета кожи, национального происхождения, расы, религии, пола,
                        сексуальной ориентации или инвалидности.
                    </p>

                    <p>
                        Объявления не должны быть связаны с алкоголем, порнографией, табаком, имитацией новостей
                        или чрезвычайной ситуации. Все объявления должны четко идентифицировать рекламодателя.
                        Любое объявление, которое подано двусмысленно и может быть неверно истолковано как рекламное,
                        будет четко обозначено как реклама.
                    </p>
                </div>

                <div class="advertising__item">
                    <h4 class="item__title">
                        №4 Разграничение рекламы и редакционного контента
                    </h4>

                    <p>
                        В каждом случае при размещении рекламы мы проводим четкую границу  между рекламными объявлениями
                        и редакционными материалами. Вся реклама на электронной медицинской информационной системы apteka24.ua четко и недвусмысленно
                        обозначена как таковая плашкой «Промо» или «Акция». Нажав на объявление или баннер, пользователь
                        перейдет на страницу рекламодателя или спонсора, расположенную на сайте apteka24.ua.
                    </p>

                    <p>
                        Редакционный контент, спонсируемый рекламодателями, регулируется нашей
                        <a href="${links.editorialPolicy}" target="_blank"> редакционной политикой</a> и
                        рассматривается нашей редакцией и <a href="${links.medicalExperts}" target="_blank">командой медицинских экспертов.</a>
                    </p>
                </div>

                <div class="advertising__item">
                    <h4 class="item__title">
                        №5 Ссылки на товары в редакционном контенте
                    </h4>

                    <p>
                        При создании редакционных материалов наши авторы статей иногда используют ссылки на
                        соответствующие товары и медикаменты, размещенные на сайте для продажи, которые, по их мнению,
                        могут быть полезны для наших читателей.
                    </p>

                    <p>
                        Несмотря на то, что все медикаменты, товары и услуги, освещенные в статьях, выбираются
                        независимо и непредвзято <a href="${links.medicalExperts}" target="_blank">командой медицинских экспертов</a>,
                        мы хотим, чтобы вы знали, что электронная медицинская информационная система apteka24.ua получает прибыль, если вы совершите покупку
                        предлагаемого товара после нажатия на одну из ссылок, которые мы предоставляем.
                    </p>
                </div>`
        },
        warning: `<strong>Обратите внимание!</strong>

                        <br />
                        <br />

                        <p>
                            Информация о медикаментах, товарах и услугах предоставлена исключительно с ознакомительной
                            целью и не должна быть использована как руководство к самолечению. Только врач может принять
                            решение о назначении препарата, а также определить дозы и способы его применения.
                        </p>

                        <p>
                            Электронная медицинская информационная система apteka24.ua не несет ответственности за возможные негативные последствия, возникшие в
                            результате использования информации, размещенной на сайте apteka24.ua.
                        </p>`
    },
    contacts: {
        title: "Контакты",
        clients: {
            toClients: "Клиентам",
            description: "Бесплатно в пределах Украины",
            workTime: "с 8:00 до 20:00 (без выходных)",
            pharmacyAddresses: "Адреса аптек"
        },
        correspondence: {
            address: "Адрес для корреспонденции",
            position: "49100, Днипро, пр.Героев, д. 11Л, каб. 321",
            time: "Пн-Пт с 9:00 – 18:00"
        },
        otherContacts: {
            items: [
                {
                    id: 1,
                    title: "Директор по маркетингу",
                    phone: "+38 (093) 122 85 39",
                    email: links.emails.marketing
                },
                {
                    id: 2,
                    title: "Поставщикам",
                    phone: "+38 (056) 795 91 78",
                    email: links.emails.supplier
                },
                {
                    id: 3,
                    title: "Оптовая продажа",
                    phone: "+38 (056) 731 94 68",
                    email: links.emails.wholesale
                },
                {
                    id: 4,
                    title: "Аренда помещений",
                    phone: "+38 (095) 352 55 33",
                    email: links.emails.rent
                }
            ]
        }
    },
    publishingPolicy: {
        title: "Политика публикации комментариев и отзывов пользователей сайта apteka24.ua",
        description: `<p>
                    Электронная медицинская информационная система apteka24.ua предоставляет пользователям возможность публиковать свои
                    комментарии и отзывы на сайте.
                </p>

                <p>
                    Цель предоставленной возможности оставлять комментарии и отзывы на сайте заключается в том,
                    чтобы дать людям возможность поделиться собственным опытом и мнениями, а также задать вопросы
                    общего или частного характера.
                </p>

                <p class="publishing-policy__alert-info alert-info">
                    Оставлять отзывы и комментарии могут только совершеннолетние пользователи, достигшие
                    18-летнего возраста
                </p>

                <p>
                    Политика публикации комментариев и отзывов пользователей apteka24.ua применяется ко всем
                    комментариям и отзывам посетителей на сайте, в мобильном приложении и в социальных сетях
                    электронной медицинской информационной системы apteka24.ua (
                    <a href="${links.facebookA24}" target="_blank">
                        Facebook
                    </a>,&nbsp;

                    <a href="${links.instagramA24}" target="_blank">
                        Instagram
                    </a>,&nbsp;

                    <a href="${links.telegramA24}" target="_blank">
                        Telegram
                    </a>
                    ).
                </p>`,
        moderation: {
            title: `Модерация`,
            content: `<p>
                        Комментарии и отзывы на сайте apteka24.ua модерируются ежедневно.
                    </p>

                    <p>
                        К  публикации допускаются только комментарии и отзывы, прошедшие модерацию&nbsp;
                        <a href="${links.team}" target="_blank">
                            редакционной командой электронной медицинской информационной системы apteka24.ua
                        </a>, которая включает проверку на:
                    </p>

                    <ul>
                        <li>соответствие возрасту (18+);</li>
                        <li>отсутствие ненормативной лексики;</li>
                        <li>
                            отсутствие неправдоподобной, потенциально опасной, угрожающей, оскорбительной
                            информации, противоречащей принципу инклюзивности.
                        </li>
                    </ul>

                    <p>
                        Пользователи, оставляющие комментарии и отзывы, не являются специалистами в области
                        здравоохранения. Если комментарий или отзыв оставляет медицинский работник, то в данном
                        случае необходимо сообщить редакции электронной медицинской информационной системы apteka24.ua на почту&nbsp;
                        <a href="mailto:editor@apteka24.ua">editor@apteka24.ua</a> или личным
                        сообщением в социальных сетях apteka24.ua о своем статусе, подтвердив его
                        соответствующими документами (диплом об образовании, сертификат, удостоверение), после чего
                        комментарию или отзыву будет присвоен статус медицинского работника.
                    </p>

                    <p>
                        Модераторы оставляют за собой право удалять любые комментарии и отзывы, которые будут
                        сочтены неуместными или нарушающими эти правила, без уведомления автора.
                    </p>`
        },
        commentsRules: {
            title: `Правила использования комментариев и отзывов`,
            content: `<p>
                        Вся информация, представленная на apteka24.ua, в том числе включающая комментарии и
                        отзывы пользователей сайта, предназначена исключительно в ознакомительных целях и
                        никоим образом не является заменой прямых отношений между пациентом и лечащим врачом.
                        Помните, что постановка диагноза и выбор методики лечения осуществляется только вашим
                        лечащим врачом!
                    </p>

                    <p>
                        Электронная медицинская информационная система apteka24.ua не несет ответственности за возможные негативные последствия,
                        возникшие в результате использования пользователями  apteka24.ua информации,
                        размещенной в разделе комментариев и отзывов.
                    </p>

                    <p>
                        Читайте подробнее полный <a href="${links.responsibility}" target="_blank">Отказ от ответственности</a>.
                    </p>`
        },
        confidential: {
            title: `Конфиденциальность`,
            content: `<p>
                        Любое сообщение, опубликованное в разделе отзывов и комментариев может быть прочитано,
                        использовано, воспроизведено и процитировано всеми пользователями apteka24.ua.
                    </p>

                    <p>
                        Узнайте больше о <a href="${links.privacyPolicy}" target="_blank">Политике конфиденциальности</a>.
                    </p>`
        },
        speakRules: {
            title: `Правила общения`,
            content: `<p>
                        Все участники коммуникации на сайте apteka24.ua (модераторы и пользователи, оставляющие
                        комментарии) обязуются следовать правилам хорошего тона, вести себя вежливо и
                        уважительно, даже если их мнения расходятся с мнениями других людей.
                    </p>

                    <p>
                        Не допускается использование ненормативной лексики и предоставление уничижительной или
                        оскорбительной информации, нарушающей принцип инклюзивности. Запрещается сообщать
                        заведомо неверную и ложную информацию, способную ввести других пользователей в
                        заблуждение и причинить потенциальный вред их здоровью и благополучию.
                    </p>

                    <p class="publishing-policy__alert-info alert-info">
                        Используя раздел комментариев и отзывов, вы соглашаетесь публиковать правдивую и точную
                        информацию, основанную на вашем личном опыте.
                    </p>

                    <p>
                        Если информация, которую вы публикуете, не является личным опытом, мы просим вас
                        предоставить источники (ссылки, книги, журналы) этой информации, когда это уместно и
                        возможно. Если вы заявляете о статистике, данных, исследованиях и новых достижениях
                        в медицине,  то такая информация обязательно должна сопровождается подтверждением из
                        надежных и официальных источников согласно&nbsp;
                        <a href="${links.editorialPolicy}" target="_blank">редакционной политике электронной медицинской информационной системы apteka24.ua</a>.
                    </p>

                    <p>
                        Пользователям сайта не разрешается размещать рекламу в сообщениях, например, в виде
                        текстовых ссылок или баннеров.
                    </p>`
        }
    },
    team: {
        title: "Наша команда",
        aboutTeam: `
            <p>Команда электронной медицинской информационной системы apteka24.ua — это люди, влюбленные в свое дело. Мы собрались вместе, чтобы
                переосмыслить образ аптечных сетей, чтобы искоренить недостоверность информирования в
                медицине и чтобы сделать здоровье трендом, а не тем, о чем стараются не говорить.
            </p>
            <p>У нас трудятся люди не просто с высшим образованием, но люди с массой талантов,
                целеустремленные, организованные, ответственные, желающие каждый день приносить успех
                компании. У нас разные специальности, но одно видение. У нас есть идея. У нас есть общая
                цель. И у нас есть стремление делать свое дело лучше, чем хорошо.
            </p>
            <p>А теперь давайте знакомиться лично ;-)</p>       
            <h2>Руководство</h2> 
        `,
        editor: `
            <h2>Редакторы</h2>
            <p>Редакция электронной медицинской информационной системы apteka24.ua родилась из людей, которые точно знают, каким должен быть текстовый сегмент сайта. В нее входят авторы статей и контент-менеджеры, которые точно следуют редакционной политике сайта и создают качественный и полезный контент.</p>
            <h3>Авторы статей</h3>
            <p>Авторы статей электронной медицинской информационной системы apteka24.ua занимаются наполнением блога полезными и релевантными материалами, следят за актуальностью и востребованностью информации, проводят интервью с экспертами и лидерами мнений, а также ведут исследовательскую журналистскую работу с зарубежными и отечественными источниками.</p>
            <h3>Контент-менеджеры</h3>
            <p>Контент-менеджеры нашей команды — это специалисты в области контент-стратегии, занимающиеся наполнением товаров инструкциями, описаниями, характеристиками и подбором аналогов, а также следящие за обновлением и верификацией инструкций, что обеспечивает актуальность важной для пациента информации.</p>
            
        `
    },
    privacyPolicy: {
        title: "Политика конфиденциальности и защиты персональных данных на сайте apteka24.ua",
        content: `<h2>Общие положения</h2>

                <p>Управление данным веб-сайтом <a href="/">www.apteka24.ua</a> (далее - «Сайт») осуществляется ООО
                    «МИС-Cервис», код ЕГРПОУ 43826536,
                    юридическим лицом, зарегистрированным и действующим в соответствии с требованиями законодательства
                    Украины (далее -
                    «Компания»).
                </p>

                <p>В настоящей политике конфиденциальности и защиты персональных данных (далее - Политика) разъясняется,
                    какая Ваша
                    информация будет собираться ООО «МИС-Cервис» при доступе к веб-сайту&nbsp;
                    <a href="/">
                        www.apteka24.ua
                    </a>&nbsp;(далее «веб-сайт»), ко всем
                    связанным веб-сайтам (поддоменам), а также к другим услугам, предоставляемым ООО «МИС-Cервис», на
                    которые ссылается
                    данная Политика. Отображается Политика, а также все другие виды связи с вами посредством письменных
                    или устных
                    средств, таких как электронная почта или телефон. В ней также описывается, как информация будет
                    использоваться, как
                    Вы можете контролировать сбор, исправление и /или удаление информации и как связаться с нами, если
                    Вам нужно.
                </p>

                <p>Настоящая Политика распространяется на персональные данные, полученные как до, так и после ее
                    введения в
                    действие.
                </p>

                <p>Наши методы обеспечения конфиденциальности разработаны для обеспечения высокого уровня защиты ваших
                    личных данных. Мы
                    не будем использовать или передавать Вашу информацию кому-либо, кроме случаев, описанных в настоящей
                    Политике
                    конфиденциальности. Настоящая Политика определена и регулируется Соглашением использования,
                    доступными на этом
                    сайте.
                </p>

                <p>Целью Политики является доведение до Вас, необходимой информации, которая позволит оценить, какие
                    персональные данные
                    и с какими целями обрабатываются нами, методы их обработки и обеспечения безопасности.
                </p>

                <p>При использовании нашего сервиса, Вы, сообщив нам свои персональные данные, в том числе посредством
                    третьих лиц,
                    признаете свое согласие на обработку своих персональных данных в соответствии с настоящей
                    Политикой.
                </p>

                <p>В случае несогласия с условиями настоящей Политики, Вы обязаны прекратить использовать наш
                    сервис.
                </p>

                <p>Нами не проверяется достоверность персональных данных, предоставляемых Вами, мы не имеем возможности
                    оценивать Вашу
                    дееспособность. При этом мы предполагаем, что Вы действуете добросовестно, осмотрительно,
                    предоставляете достоверные
                    и достаточные персональные данные и прилагаете все необходимые усилия в поддержке таких данных в
                    актуальном
                    состоянии, и не нарушаете права третьих лиц.
                </p>

                <p>Соглашаясь с условиями настоящей Политики, Вы подтверждаете, что в момент сбора персональных данных
                    Вы уведомлены о
                    лицах, которым передаются персональные данные, содержании и целях сбора персональных данных. Вы
                    подтверждаете
                    (гарантируете), что персональные данные, которые передали нам на обработку, переданы с согласия
                    владельцев
                    персональных данных и в рамках Законодательства.
                </p>

                <p>Вы также соглашаетесь с тем, что Владелец персональных данных имеет право предоставлять доступ и
                    передавать Ваши
                    персональные данные третьим лицам без каких-либо дополнительных сообщений, исключительно, если при
                    этом не меняется
                    цель их обработки и только в случаях, предусмотренных данной Политикой конфиденциальности и/или
                    законодательством
                    Украины.
                </p>
                <p>Лица в возрасте до 18 лет не должны предоставлять нам персональную информацию через Онлайн-сервис.
                    Нами не собирается
                    целенаправленно персональная информация лиц младше 18 лет. Родители и опекуны должны постоянно
                    контролировать
                    связанную с этим деятельность своих детей.
                </p>

                <p>Пожалуйста, внимательно прочитайте эту Политику конфиденциальности, чтобы понять наши взгляды и
                    практику в отношении
                    Ваших личных данных, прежде чем просматривать этот веб-сайт.
                </p>

                <h2>1. Сбор и использование персональных данных</h2>

                <p>Владельцем и распорядителем Ваших персональных данных является ООО «МИС-Cервис».
                </p>

                <p>Компания собирает только те персональные данные (например, Ваше имя и фамилию, логин и пароль доступа
                    к личному
                    кабинету на сайте
                    &nbsp;
                    <a href="/">www.apteka24.ua</a>, адрес электронной почты, номер контактного телефона, дата рождения,
                    пол и т.д.), которые сознательно и добровольно предоставлены Вами как субъектом персональных данных
                    в целях
                    использования сервисов Сайта, что в соответствии с требованиями законодательства является согласием
                    субъекта
                    персональных данных на обработку своих персональных данных в соответствии с сформулированной в этой
                    Политике целью
                    их обработки.
                </p>

                <p><strong>Состав персональных данных.</strong> Для осуществления своей деятельности и для выполнения
                    своих обязательств нами
                    обрабатываются Ваши персональные данные, предоставленные при регистрации на сайте
                    &nbsp;
                    <a href="/">
                        www.apteka24.ua
                    </a>&nbsp; и хранящиеся в
                    личном кабинете.
                </p>

                <p>К персональным данным относятся: фамилия, имя, отчество, адрес электронной почты, номер
                    мобильного/стационарного
                    телефона, страна проживания, место работы, должность, дата рождения.
                </p>

                <p>Мы просим сообщать только те персональные данные, которые необходимы для предоставления выбранной
                    Вами услуги,
                    получение информационной рассылки или ответа на Ваш специальный запрос. Если сообщите нам
                    дополнительные
                    персональные данные, мы также сможем обработать их с необходимым уровнем защиты.
                </p>

                <p>Данные, которые автоматически нам передаются при использовании Вами нашего сервиса с помощью
                    установленного на
                    устройстве программного обеспечения: IP-адрес, информация о браузере и вид операционной системы
                    устройства,
                    технические характеристики оборудования и программного обеспечения, дата и время доступа к
                    онлайн-сервису.
                </p>

                <ul>
                    <li>Мы запрашиваем Ваши персональные и контактные данные только для осуществления Вашего заказа и
                        предоставления
                        необходимой информации о состоянии его выполнения, регистрации в личном кабинете клиента,
                        участия в рекламных
                        активностях ООО «МИС-Cервис» и тому подобное.
                    </li>

                    <li>Ваши персональные данные обрабатываются ООО «МИС-Cервис» исключительно в понимании Закона Украины
                        «О защите
                        персональных данных». Такие данные, в соответствии с требованиями законодательства,
                        регулирующего
                        правоотношения, связанные с защитой персональных данных, сохраняются, обрабатываются и
                        используются в рамках
                        всех правоотношений, возникающих между Вами и ООО «МИС-Cервис». Все правоотношения, связанные с
                        обработкой и
                        защитой персональных данных, регулируются соответствующим законодательством.
                    </li>

                    <li>Ваши персональные данные обрабатываются в следующих случаях:</li>

                    <li>при осуществлении (подтверждении) Вами заказа на сайте apteka24.ua, то есть при приобретении
                        товаров и / или
                        услуг, путем клика в строке «Оформить заказ и отправить заказ»;
                    </li>

                    <li>при регистрации в личном кабинете клиента на сайте apteka24.ua;</li>

                    <li>при участии в рекламных активностях ООО «МИС-Cервис» в соответствии с правилами проведения таких
                        мероприятий.
                    </li>

                    <li>Также вы даете свое согласие на передачу (распространение) компанией ООО «МИС-Cервис» ваших данных
                        транспортно-экспедиторским и курьерским организациям, банкам или финансовым учреждениям и иным
                        третьим лицам,
                        обеспечивающим выполнение Вашего заказа на приобретение товаров или исполнения заказанных услуг.
                    </li>

                    <li>Такой перечень порядка предоставления согласия на обработку Ваших персональных данных не
                        является исчерпывающим
                        и согласовывается ООО «МИС-Cервис» и клиентом (потребителем) и / или доводится до сведения клиента
                        (потребителя).
                    </li>

                    <li>ООО «МИС-Cервис» гарантирует, что персональные данные будут храниться и обрабатываться в
                        соответствии с
                        требованиями Закона Украины «О защите персональных данных».
                    </li>

                    <li>ООО «МИС-Cервис» использует все необходимые (cookies) куки, чтобы обеспечить Вам предоставление
                        максимально полной
                        информации о нас и наших возможностях, чтобы Вы получили лучший опыт на нашем сайте. Если Вы
                        продолжаете
                        пользоваться нашим сайтом, это означает Ваше согласие на использование нами (cookies) и
                        получения Вами всех
                        файлов cookie, и нашу возможность отправлять Вам самую свежую информацию о самых актуальных
                        предложениях и
                        акциях. Вы в любое время можете легко отказаться от рассылки или запретить использовать куки
                        путем персональных
                        настроек Вашего девайса.
                    </li>

                    <li>При посещении сайта apteka24.ua мы собираем, храним и обрабатываем такую информацию как
                        IP-адрес, дату и время
                        посещения, тип и язык браузера, файлы cookies — исключительно для предоставления услуг сайта и
                        оптимизации его
                        работы, а также для защиты целостности информационного пространства сайта.
                    </li>

                    <li>Эта информация является конфиденциальной и не передаётся третьим лицам, кроме обоснованных
                        случаев,
                        предусмотренных законами Украины, по просьбе субъекта персональных данных или в других случаях,
                        изложенных в
                        данной Политике.
                    </li>
                </ul>

                <h2>2. Цели использования персональных данных</h2>

                <p>Ваши персональные данные используются с целью обеспечения предоставления сервисов сайта в
                    соответствии и во
                    исполнение законов Украины, в том числе, но не исключительно: «О защите персональных данных», «Об
                    информации», «О
                    рекламе», «О телекоммуникациях», «О защите информации в информационно-телекоммуникационных
                    системах», Генерального
                    регламента о защите данных (ЕС) 2016/679 от 27.04.2016 г. (EU General Data Protection Regulation,
                    далее - GDPR) и
                    другого применимого Европейского законодательства о защите данных, совместно именуемое далее –
                    Законодательство, а
                    также в соответствии с Соглашением об использовании сайта и других актов, регулирующих деятельность
                    Компании.
                </p>
                <p><strong>Целью обработки Ваших персональных данных является:</strong>
                </p>

                <ul>
                    <li>осуществление возложенных на нас функций в соответствии с законодательством Украины и GDPR;</li>

                    <li>сбор, хранение и обработка персональных данных полученных в онлайн-сервисе в рамках Закона и
                        GDPR;
                    </li>

                    <li>для отправления Вам коммерческих (маркетинговых) уведомлений, содержащих дополнительную
                        информацию об услугах,
                        актуальных акциях и специальных предложениях, касающихся услуг, предоставляемых нами с помощью
                        нашего
                        онлайн-сервиса;
                    </li>

                    <li>идентификация субъекта персональных данных при использовании онлайн-сервиса;</li>

                    <li>связь с субъектом персональных данных в случае необходимости, в том числе, направление
                        предложений,
                        информационных материалов, сообщений, информации и запросов, рекламы, а также обработки запросов
                        субъекта
                        персональных данных;
                    </li>

                    <li>улучшение качества онлайн-сервиса, удобства его использования, разработка новых функциональных
                        возможностей и
                        улучшение качества обслуживания;
                    </li>

                    <li>проведение статистических и других исследований на основе обезличенных данных.</li>

                </ul>

                <h2>3. Использование файлов Cookies</h2>

                <p>Cookie - это текстовый файл или файлы, содержащие небольшой объем информации, которые отправляются
                    веб-браузеру и
                    хранятся на Вашем устройстве (компьютер, мобильный телефон или другое устройство, с помощью которого
                    Вы посещаете
                    Сайт).
                </p>
                <p>Сookie файлы могут быть постоянными и храниться в компьютере, пока Вы их не удалите, или временными/
                    сессионными -
                    они хранятся только до закрытия браузера.
                </p>
                <p>Обращаем внимание, что:
                </p>
                <ul>
                    <li>при повторном посещении Вами сайта, данные файлов cookie обновляются;</li>

                    <li>в большинстве случаев, веб-браузер по умолчанию допускает автоматическое сохранение файлов
                        cookie на Вашем
                        устройстве;
                    </li>

                    <li>отключение файлов cookie может привести к ограничению доступа к опубликованным материалам и /
                        или неполноценному
                        функционированию сервисов Сайта.
                    </li>
                </ul>

                <p>Мы делаем все для того, чтобы сделать Ваше пребывание на сайте максимально комфортным, для этого, с
                    помощью файлов
                    cookie, нам необходимо анализировать поведение, предпочтения и Ваши интересы, что поможет нам
                    улучшить опыт
                    взаимодействия с сайтом, определить наиболее удобный интерфейс и навигацию сервиса.
                </p>

                <p>В соответствии с классификацией Международной торговой палаты (International Chamber of Commerce),
                    нами используются
                    такие категории файлов cookie:
                </p>
                <p>Строго необходимые файлы cookie, которые нужны для перемещения пользователем по веб-странице и при
                    использовании
                    определенных сервисов (для доступа к защищенным страницам, регистрации и авторизации, осуществления
                    поиска по сайту,
                    запоминания ваших предыдущих действий при переходе на предыдущую страницу в той же сессии).
                </p>
                <p>Эксплуатационные файлы cookie - агрегируют информацию о том, как используется сайт. Такие данные
                    хранятся на Вашем
                    устройстве между сеансами веб-браузера (время пребывания на сайте, наиболее часто посещаемые
                    страницы, понимание,
                    какие именно разделы и сервисы сайта были наиболее интересны для вас, эффективность той или иной
                    рекламной и / или
                    маркетинговой кампания и т.д.). Информация, собранная с помощью эксплуатационных файлов cookie,
                    предназначена для
                    статистических и аналитических задач.
                </p>
                <p>Функциональные файлы cookie - используются для сохранения параметров или конфигураций, которые
                    хранятся на Вашем
                    устройстве между сеансами веб-браузера (имя пользователя, фото в профиле, информация по оставленным
                    комментариям,
                    язык сайта, местонахождение, сведения о том, предоставлялась ли Вам какая-либо информация или
                    выбранные преимущества
                    ранее, а также другие параметры настройки сайта). Такие файлы cookie также позволяют Вам смотреть
                    видео, участвовать
                    в интерактивах (опросы, голосования ) и взаимодействовать с социальными сетями.
                </p>
                <p>Некоторые данные файлов cookie могут предоставляться третьим сторонам, которые имеют разрешение со
                    стороны
                    веб-ресурса, и исключительно для указанных выше целей.
                </p>
                <p>Целевые файлы cookie - используются для предоставления контента, который может Вас заинтересовать.
                    Эти данные
                    хранятся на Вашем устройстве между сеансами веб-браузера (отслеживание рекомендованного текстового,
                    графического,
                    аудио и видеоматериала, с целью избежать повторного показа, управление целевой рекламой, оценка
                    эффективности
                    рекламных кампаний, информация о посещении пользователем других ресурсов при переходах). Сайт может
                    делиться этой
                    информацией с другими сторонами, включая медиа-клиентов, рекламодателей, агентств и партнеров по
                    смежным бизнесам
                    для того, чтобы они предоставляли качественную таргетированную рекламу.
                </p>
                <p><strong>Cookie-файлы сторонних сервисов и сервисов аналитики:</strong>
                </p>
                <p>Для оперативной доставки, более качественного отображения и детального анализа контента на Сайте, мы
                    пользуемся
                    услугами, которые являются собственностью других сторонних компаний, таких как Facebook, Twitter,
                    Instagram,
                    Alphabet Inc., Hotjar и другие. Приведенные в качестве примера компании могут использовать файлы
                    cookie на Вашем устройстве, во
                    время работы на сайте. Следует обратить внимание, что сайт не может повлиять на работу файлов
                    cookie, которые
                    используются этими сервисами. Все необходимые сведения об их использовании можно узнать, посетив
                    соответствующий
                    ресурс.
                </p>
                <ul>
                    <li>Условия использования&nbsp;
                        <a
                            target="_blank"
                            href="http://www.google.com/analytics/terms/ru.html"
                        >Google Analytics
                        </a>
                    </li>

                    <li>Политика использования данных&nbsp;
                        <a
                            target="_blank"
                            href="https://www.facebook.com/about/privacy/"
                        >Facebook
                        </a>
                    </li>

                    <li>Политика использования&nbsp;
                        <a
                            target="_blank"
                            href="https://www.hotjar.com/legal/policies/privacy/"
                        >Hotjar
                        </a>
                    </li>

                    <li>Политика приватности <a target="_blank" href="https://www.admitad.com/ua/privacy/">Admitad</a>
                    </li>

                    <li>Политика конфиденциальности&nbsp;
                        <a
                            target="_blank"
                            href="https://esputnik.com/politika-konfidencialnosti"
                        >eSputnik
                        </a>
                    </li>
                </ul>
                <p><strong>Управление файлами cookie:</strong>
                </p>

                <p>Основные веб-браузеры (перечисленные ниже) настроены на автоматический прием файлов cookie. Для того,
                    чтобы их
                    отключить, воспользуйтесь функцией справки в своем браузере. Справку можно вызвать через меню или с
                    помощью кнопки
                    F1.
                </p>

                <ul>
                    <li>
                        <a
                            target="_blank"
                            href="https://privacy.microsoft.com/ru-ru/privacystatement"
                        >Microsoft Edge
                        </a>
                    </li>

                    <li>
                        <a
                            target="_blank"
                            href="https://www.mozilla.org/ru/privacy/websites/#cookies"
                        >Mozilla Firefox
                        </a>
                    </li>

                    <li><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=ru">Google Chrome</a>
                    </li>

                    <li><a target="_blank" href="http://help.opera.com/Windows/11.50/ru/cookies.html">Opera</a></li>

                    <li><a target="_blank" href="https://support.apple.com/uk-ua/guide/safari/sfri11471/mac">Safari for macOS</a>
                    </li>
                </ul>

                <p><strong>Обращаем внимание:</strong>
                </p>

                <ul>
                    <li>конфигурация настроек файлов cookie для веб-браузеров мобильных устройств может отличаться;</li>

                    <li>следует напомнить, что полноценная работа с сайтом доступна только при использовании файлов
                        cookie;
                    </li>

                    <li>отключение файлов cookie может привести к ограничению доступа к содержанию и к неполноценному
                        функционированию
                        сервисов сайта.
                    </li>
                </ul>

                <p>Чтобы обратиться к нам по поводу использования файлов cookie, отправьте сообщение по электронной
                    почте на email&nbsp;
                    <a href="mailto:client@apteka24.ua">client@apteka24.ua</a>
                </p>
                <p>Если Вы не включаете использование файлов cookie или намеренно удаляете все файлы cookie из своего
                    веб-браузера, то
                    при дальнейшем посещении сайта Вам будет повторно предложено включение и использование файлов
                    cookie.
                </p>
                <p>Информация о Вас, полученная с помощью файлов cookie, не продается и не распространяется в открытом
                    доступе, является
                    собственностью компании, которой принадлежит ресурс.
                </p>

                <h2>4. Ссылки на другие сайты</h2>

                <p>Этот сайт содержит ссылки на другие сайты. Настоящая Политика конфиденциальности относится только к
                    этому сайту,
                    поэтому, когда вы переходите по ссылке на другие сайты, вы должны прочитать их собственную политику
                    конфиденциальности. ООО «МИС-Cервис» не несет ответственности за политику конфиденциальности или
                    содержание таких
                    веб-сайтов.
                </p>

                <h2>5. Условия безопасности хранения персональных данных</h2>
                <p>Мы храним ваши персональные данные до тех пор, пока это необходимо для выполнения целей, для которых
                    они были
                    собраны, как описано выше. Критерии, которые мы используем для определения сроков хранения данных
                    для персональных
                    данных, включают следующее: хранение в соответствии с требованиями законодательства и нормативных
                    требований. Мы
                    можем хранить и использовать вашу информацию для выполнения наших юридических обязательств,
                    разрешения споров,
                    предотвращения злоупотреблений.
                </p>
                <p>Мы стремимся использовать соответствующие технические и физические меры безопасности для защиты ваших
                    личных данных,
                    которые мы передаем, храним или обрабатываем иным образом, от случайного или незаконного
                    уничтожения, потери,
                    изменения, несанкционированного раскрытия или доступа.
                </p>
                <p>При использовании Вами сервисов, на страницах Сайта могут присутствовать коды других
                    интернет-ресурсов и третьих лиц,
                    в результате чего такие интернет-ресурсы и третьи лица получают Ваши данные. Следовательно, эти
                    интернет-ресурсы
                    могут получать и обрабатывать информацию о том, что Вы посетили эти страницы, а также другую
                    информацию, которую
                    передает браузер пользователя. Такими Интернет-ресурсами могут быть:
                </p>
                <ul>
                    <li>системы банеропоказов (например, DoubleClick for Publishers, Admixer, AdRiver и др. )</li>

                    <li>социальные плагины сетей (например, Discus, Facebook, Twitter, Google+)</li>
                </ul>
                <p>Использование указанных сервисов Нам необходимо для оперативного анализа посещений сайта, внутренней
                    и внешней оценки
                    посещаемости сайта, глубины просмотра, активности пользователей. Данные, полученные от указанных
                    сервисов, мы не
                    сохраняет и не обрабатывает.
                </p>
                <p>Соответственно, если в силу каких-либо причин Вы не желаете, чтобы указанные сервисы получали доступ
                    к Вашим
                    персональным данным, Вы можете по собственному желанию выйти из своего аккаунта или профиля,
                    очистить файлы cookie
                    (через свой браузер).
                </p>

                <h2>6. Защита персональных данных</h2>

                <p>ООО «МИС-Cервис» использует общепринятые стандарты технологической и операционной защиты информации и
                    персональных
                    данных от потери, неправильного использования, изменения или уничтожения. При этом, несмотря на все
                    усилия, мы не
                    может гарантировать абсолютную защищенность от любых угроз, которые возникают вне пределов
                    регулирования нашей
                    компанией. Нами обеспечивается применение всех соответствующих обязательств относительно соблюдения
                    конфиденциальности, а также технических и организационных мер безопасности для предотвращения
                    несанкционированного
                    или незаконного разглашения или обработки такой информации и данных, их случайной потери,
                    уничтожения или
                    повреждения. Мы предоставляем доступ к информации и персональным данным только уполномоченным
                    работникам, которые
                    дали согласие на обеспечение конфиденциальности такой информации и данных в соответствии с
                    требованиями ООО «МИС-Cервис». Распространение персональных данных без согласия субъекта персональных данных или
                    уполномоченного им
                    лица разрешается в случаях, определенных законом, и только (если это необходимо) в интересах
                    национальной
                    безопасности, экономического благосостояния и прав человека.
                </p>

                <h2>7. Ваши права как субъекта персональных данных</h2>
                <p>Компания доводит до Вашего сведения информацию о Ваших правах как субъекта персональных данных,
                    которые урегулированы
                    Законом Украины «О защите персональных данных», а именно:
                </p>
                <ol>
                    <li>знать об источниках сбора, местонахождении своих персональных данных, цели их обработки,
                        местонахождении или
                        местожительстве (пребывании) владельца или распорядителя персональных данных или предоставить
                        соответствующее
                        поручение относительно получения этой информации уполномоченным им лицам, кроме случаев,
                        установленных законом;
                    </li>

                    <li>получать информацию об условиях предоставления доступа к персональным данным, в частности,
                        информацию о третьих
                        лицах, которым передаются персональные данные;
                    </li>

                    <li>на доступ к своим персональным данным;</li>

                    <li>получать не позднее, чем за тридцать календарных дней со дня поступления запроса, кроме случаев,
                        предусмотренных
                        законом, ответ о том, обрабатываются ли персональные данные, а также получать содержание таких
                        персональных
                        данных;
                    </li>

                    <li>предъявлять мотивированное требование владельцу персональных данных с возражением против
                        обработки своих
                        персональных данных;
                    </li>

                    <li>предъявлять мотивированное требование относительно изменения или уничтожения своих персональных
                        данных любым
                        владельцем и распорядителем персональных данных, если эти данные обрабатываются незаконно или
                        являются
                        недостоверными;
                    </li>

                    <li>на защиту своих персональных данных от незаконной обработки и случайной потери, уничтожения,
                        повреждения в связи
                        с умышленным сокрытием, непредоставлением или несвоевременным их предоставлением, а также на
                        защиту от
                        предоставления сведений, которые являются недостоверными или позорят честь, достоинство и
                        деловую репутацию
                        физического лица;
                    </li>

                    <li>обращаться с жалобами на обработку своих персональных данных к Уполномоченному Верховной Рады
                        Украины по правам
                        человека или в суд;
                    </li>

                    <li>применять средства правовой защиты в случае нарушения законодательства о защите персональных
                        данных;
                    </li>

                    <li>вносить оговорки относительно ограничения права на обработку своих персональных данных во время
                        предоставления
                        согласия;
                    </li>

                    <li>отозвать согласие на обработку персональных данных;</li>

                    <li>знать механизм автоматической обработки персональных данных;</li>

                    <li>на защиту от автоматизированного решения, которое имеет правовые последствия.</li>
                </ol>

                <p>Для обновления, получения доступа, внесения изменений, блокировки или удаления своих персональных
                    данных, отзыва
                    согласия на обработку персональных данных, которое Вами предоставлено нам согласно этой Политике,
                    или в случае
                    наличия каких-либо замечаний, пожеланий или претензий относительно Ваших персональных данных,
                    которые нами
                    обрабатываются, пожалуйста, обращайтесь к нам: по электронной почте&nbsp;
                    <a href="mailto:client@apteka24.ua">client@apteka24.ua</a> или напишите письмо на адрес:
                    49100, г. Днипро, пр.Героев, д. 11Л, каб. 321.
                </p>

                <h2>Изменения в Политике конфиденциальности и защиты персональных данных</h2>
                <p>Мы оставляем за собой право изменять настоящую Политику конфиденциальности в любое время по
                    собственному усмотрению,
                    без предварительного уведомления. Если мы внесем изменения в настоящую Политику конфиденциальности,
                    они будут
                    размещены на веб-сайте, чтобы вы владели актуальной информацией. Новая редакция Политики вступает в
                    силу с момента
                    ее размещения на сайте, если иной срок не предусмотрен новой редакцией Политики. Если после
                    ознакомления с
                    изменениями Вы не откажетесь от их принятия в письменной форме, это будет означать, что Вы
                    соглашаетесь с
                    соответствующими изменениями Политики. Предлагаем периодически пересматривать Политику для того,
                    чтобы быть
                    проинформированным об изменениях или дополнениях.
                </p>`
    },
    massMedia: {
        title: "СМИ о нас"
    },
    agreement: {
        title: "Соглашение об использовании apteka24.ua. Предмет соглашения",
        content: `<p class="warning-text color-red">
                    Пожалуйста, перед просмотром нашего сайта внимательно прочитайте условия Соглашения. Если Вы не
                    согласны с условиями, не используйте наш сайт
                </p>
                <p>
                    Настоящее соглашение регулирует отношения между Владельцем Сайта, и Вами (далее - Пользователь),
                    возникающие в связи с использованием Сайта.
                </p>
                <h2>Термины Соглашения</h2>
                <p>
                    Для целей настоящего Соглашения следующие слова и выражения имеют указанные ниже значения:
                </p>
                <ul>
                    <li><strong>Владелец</strong> - Общество с ограниченной ответственностью «МИС-Cервис» (код ЕГРПОУ
                        43826536) находящееся по адресу: 49100, г. Днипро, пр.Героев, д. 11Л, каб. 321,
                        являющееся одной из сторон Соглашения.
                    </li>
                    <li><strong>Пользователь</strong> - Означает любое лицо, которое обращается, подключается или
                        оказывает любому стороннему лицу содействие в получении любого рода информации или
                        информационному наполнению, которые размещены на Сайте Владельца, а равно и связаны с упомянутым
                        Сайтом ссылками, загруженными с Сайта, либо полученными путем обращения к Сайту, в том числе
                        добровольно прошедшее регистрацию на Сайте, являющееся одной из сторон Соглашения.
                    </li>
                    <li><strong>Соглашение</strong> - настоящее Соглашение, заключенное Владельцем и Пользователем.</li>
                    <li><strong>Сторона и Стороны</strong> - означает соответственно Владельца или Пользователя в
                        отдельности, либо Владельца и Пользователя совместно.
                    </li>
                    <li><strong>Регистрация</strong> - процедура, в ходе которой Пользователь предоставляет данные о
                        себе по утвержденной Владельцем форме. Регистрация считается завершенной только в случае
                        успешного прохождения Пользователем всех ее этапов, включая активацию, осуществляемую путем
                        нажатия кнопки «Регистрация», что является автоматическим подтверждением добровольной
                        Регистрации Пользователя, без нажатия соответствующей кнопки Регистрация не осуществляется -
                        учетная запись не создается. Настоящим Пользователь подтверждает, что дает согласие на
                        автоматизированную, а также без использования средств автоматизации обработку его персональных
                        данных, предоставляемых им при Регистрации Владельцу.
                    </li>
                    <li><strong>Логин</strong> - адрес Электронной почты Пользователя, использующийся для идентификации
                        Пользователя, и в сочетании с Паролем позволяющий получить доступ Пользователя ко всем или
                        отдельным сервисам/модулям Сайта.
                    </li>
                    <li><strong>Пароль</strong> - буквенно-цифровой код, сгенерированный Пользователем и предоставленный
                        Владельцу в процессе прохождения Регистрации, хранимый обеими сторонами Соглашения в тайне от
                        третьих лиц, и используемый в сочетании с Логином для получения доступа Пользователя к
                        сервисам/модулям Сайта.
                    </li>
                    <li><strong>Регистрационные данные Пользователя</strong> - данные, добровольно указанные
                        Пользователем при прохождении Регистрации (ФИО, контактный телефон, адрес электронной почты).
                        Данные хранятся Владельцем и подлежат использованию исключительно в соответствии с Соглашением и
                        законодательством Украины.
                    </li>
                    <li><strong>Сайт</strong> - информационный проект Владельца, размещенный в сети Интернет по
                        адресу: <a href="/">www.apteka24.ua</a> и его зеркалам. Позволяющий посредством глобальной сети
                        Интернет и различных электронных устройств, просматривать в режиме онлайн различный Контент
                        Сайта.
                    </li>
                    <li><strong>Контент Сайта</strong> - размещаемые Владельцем информация, текстовые материалы,
                        фотоматериалы, иные статичные изображения, видеоматериалы, которые доступны к просмотру без
                        взимания с Пользователя платы.
                    </li>
                    <li><strong>Личный кабинет (Учетная запись) Пользователя</strong> - формируемые в результате
                        Регистрации сведения, необходимые в том числе для идентификации Пользователя и его доступа к
                        отдельным сервисам/модулям Сайта, включая, но не ограничиваясь: Логин, Пароль.
                    </li>
                </ul>
                <h2>Общие положения</h2>
                <p>
                    Соглашение устанавливает правила и условия использования Сайта Пользователями, которые после
                    Регистрации или без таковой, осуществляют использование Сайта и его служб, становятся Пользователями
                    Сайта и услуг, предоставляемых Владельцем.
                </p>
                <p>
                    Настоящее Соглашение об использовании Сайта заключается между Владельцем, и любым Пользователем.
                    Обращение к любому из разделов Сайта, а равно и размещение на него ссылок, цитирование и перепечатка
                    Контента Сайта, подразумевают юридически обязывающее согласие Пользователя на полное и
                    безоговорочное соблюдение условий и положений настоящего Соглашения.
                </p>
                <p>
                    Настоящее Соглашение не является договором. Владелец Сайта оставляет за собой право, как изменить
                    настоящее Соглашение, так и ввести новое. Подобные изменения вступают в силу с момента их размещения
                    на Сайте. Использование Пользователем материалов Сайта после изменения Соглашения автоматически
                    означает их принятие.
                </p>
                <p>
                    Если Вы (Пользователь) не согласны соблюдать Соглашение, не используйте Сайт, а если являетесь
                    зарегистрированным Пользователем, незамедлительно прекратите использование Сайта.
                </p>
                <p>
                    Пользователь обязуется регулярно знакомиться с содержанием Соглашения, в целях своевременного
                    ознакомления с его изменениями.
                </p>
                <p class="color-blue">
                    Предназначением Сайта является ознакомление Пользователя с Контентом Сайта и обеспечение
                    информационной поддержки Пользователей.
                </p>
                <p>
                    Запрещается любое незаконное использование настоящего Сайта, размещенной на нем Информации и иных
                    материалов.
                </p>
                <p>
                    Ничто в Соглашении не может толковаться или пониматься как установление между Владельцем и
                    Пользователем агентских отношений, отношений товарищества, отношений по совместной деятельности,
                    отношений личного найма, либо иных отношений, прямо не предусмотренных Соглашением.
                </p>
                <p>
                    Владелец вправе совмещать размещенные Пользователями на Сайте сообщения или отзывы с информацией
                    рекламного характера в форме графических или текстовых модулей (баннеров).
                </p>
                <h2>Правила использования Информационных и иных материалов Сайта</h2>
                <p>
                    Любое использование Информации и материалов Сайта допускается только с разрешения Владельца Сайта.
                    Под использованием Информации Сайта подразумевается - любое воспроизведение, распространение,
                    переработка и другие способы использования, предусмотренные Законом Украины «Об авторском праве и
                    смежных правах».
                </p>
                <p>
                    Любое использование Информации и иных материалов, размещенных на Сайте, требует письменного
                    разрешения Владельца Сайта, с указанием способа разрешенного использования, срока использования,
                    условий использования.
                </p>
                <h2>Права и обязанности Пользователя</h2>
                <p>
                    Пользователь обязуется использовать Сайт только в законных целях, соблюдать действующее
                    законодательство Украины, а также права и законные интересы Владельца и правообладателей, чей
                    Контент размещен на Сайте.
                </p>
                <p>
                    Пользователь обязан не использовать Сайт для Регистрации более одной Учетной записи на одно и то же
                    лицо.
                </p>
                <p>
                    Пользователь обязан воздерживаться от осуществления действий, направленных на дестабилизацию работы
                    Сайта, осуществления попыток несанкционированного доступа к Сайту, а также от осуществления любых
                    иных действий, нарушающих права Владельца и/или третьих лиц.
                </p>
                <p>
                    Пользователь обязуется не направлять через/посредством Сайта материалы, являющиеся рекламой
                    каких-либо товаров или услуг, без получения предварительного письменного согласия Владельца.
                    Пользователь обязуется не использовать Сайт для рекламы или иного стимулирования сбыта любых товаров
                    и услуг.
                </p>
                <p>
                    Пользователь не имеет права воспроизводить, повторять, копировать, продавать, перепродавать, а также
                    использовать любым способом для каких-либо коммерческих целей Сайт и (или) какие-либо части
                    содержимого Сайта без согласия Владельца.
                </p>
                <p>
                    Пользователь имеет право прекратить использование Сайта и отказаться от созданной им Учетной записи,
                    направив Владельцу на адрес электронной почты&nbsp;
                    <a href="mailto:info@apteka24.ua">
                        info@apteka24.ua
                    </a> со своего адреса Электронной почты, указанного
                    при Регистрации, запрос на удаление Учетной записи с Сайта. Администратор удаляет учетную запись
                    Пользователя в течение 10 (десяти) рабочих дней после получения его запроса, соответствующего
                    условиям, указанным выше.
                </p>
                <p>
                    Пользователь обязан принимать надлежащие меры для обеспечения сохранности данных Учетной записи
                    Пользователя (включая адрес электронной почты, логин и пароль) и несет ответственность за все
                    действия, совершенные на Сайте под его учетной записью. В связи с указанным, Пользователь обязан
                    осуществлять выход из своей Учетной записи (завершать каждую сессию по кнопке «Выход») перед
                    переходом на сторонние сайты или закрытием браузера (Интернет-обозревателя). Пользователь обязан
                    незамедлительно уведомить Владельца о любых случаях доступа на Сайт третьими лицами под учетной
                    записью Пользователя. Пользователь не имеет права передавать, уступать, продавать, передавать в
                    пользование и т.п. Учетную запись на Сайте третьим лицам без согласия Владельца.
                </p>
                <p>
                    Пользователь обязан периодически обновлять персональную информацию, которую он представил при
                    Регистрации, чтобы обеспечить ее точность, актуальность и полноту.
                </p>
                <p>
                    Пользователь несет полную ответственность за любые действия, совершенные им с использованием его
                    Учетной записи, а также за любые последствия, которые могло повлечь или повлекло подобное его
                    использование.
                </p>
                <p>
                    Пользователь обязан использовать полученную на Сайте информацию исключительно в законных и личных
                    некоммерческих целях, не противоречащих нравственным принципам и общепризнанным ценностям.
                </p>
                <p>
                    Пользователь обязуется не пытаться изменять или модифицировать никакую часть Сайта, а также не
                    использовать Сайт и размещенный на нем Контент в коммерческих целях.
                </p>
                <p>
                    Пользователи несут ответственность за собственные действия в связи с созданием и размещением
                    комментариев, сообщений или отзывов на Сайте, а также в связи с размещением информации в Учетной
                    записи в соответствии с действующим законодательством Украины.
                </p>
                <p>
                    Пользователю при использовании Сайта запрещается загружать, хранить, публиковать, распространять и
                    предоставлять доступ или иным образом использовать любую информацию, которая:
                </p>
                <ul>
                    <li>содержит угрозы, дискредитирует, оскорбляет, порочит честь и достоинство или деловую репутацию,
                        или нарушает неприкосновенность частной жизни других Пользователей или третьих лиц;
                    </li>
                    <li>является вульгарной или непристойной, содержит нецензурную лексику, содержит порнографические
                        тексты или тексты сексуального характера;
                    </li>
                    <li>содержит описание средств и способов суицида, любое подстрекательство к его совершению;</li>
                    <li>пропагандирует и/или способствует разжиганию расовой, религиозной, этнической ненависти или
                        вражды, пропагандирует фашизм или идеологию расового превосходства;
                    </li>
                    <li>пропагандирует преступную деятельность или содержит советы, инструкции или руководства по
                        совершению преступных действий;
                    </li>
                    <li>содержит информацию ограниченного доступа, включая, но не ограничиваясь, государственной и
                        коммерческой тайной, информацией о частной жизни третьих лиц;
                    </li>
                    <li>содержит рекламу;</li>
                    <li>носит мошеннический характер;</li>
                    <li>является интеллектуальной собственностью третьих лиц, не дававших разрешение Пользователю на ее
                        использование, а также нарушает иные права и интересы граждан, юридических лиц или требования
                        законодательства Украины.
                    </li>
                </ul>
                <p>
                    Пользователь обязан выполнять иные обязанности, установленные Соглашением, выполнять указания и
                    требования Владельца.
                </p>
                <h2>Права и обязанности Владельца</h2>
                <p>
                    Обязанности Владельца заключаются исключительно в обеспечении предоставления технической возможности
                    получения Пользователем демонстрационного доступа к Сайту в порядке, определенном Соглашением.
                </p>
                <p>
                    Владелец оставляет за собой право по своему собственному усмотрению изменять или удалять любую
                    публикуемую на Сайте информацию, а также фото- и видеоконтент, и любые элементы, и составные части
                    Сайта, приостанавливать, ограничивать или прекращать доступ Пользователя ко всем или к любому из
                    разделов/модулей Сайта в любое время по любой причине или без объяснения причин, с предварительным
                    уведомлением или без такового (по усмотрению Владельца). При этом Стороны соглашаются, что Владелец
                    не отвечает за любой вред, который может быть причинен Пользователю такими действиями.
                </p>
                <p>
                    Владелец вправе устанавливать любые ограничения в использовании Сайта, в любое время изменять
                    Соглашение в одностороннем порядке без получения согласия Пользователя.
                </p>
                <p>
                    Владелец вправе осуществлять рассылки Пользователям сообщений на Электронную почту, указанную
                    Пользователем при Регистрации, содержащих организационно-техническую или иную информацию. Если
                    Владельцу станет известно, что Электронная почта зарегистрированного Пользователя не существует или
                    является недействующей, Регистрация соответствующего Пользователя будет приостановлена.
                </p>
                <p>
                    Владелец вправе предоставить Пользователю возможность создания единственной персональной Учетной
                    записи. В случае создания Пользователем более одной Учетной записи Владелец оставляет за собой право
                    отказать в создании Пользователем дублирующей Учетные записи и/или отказать Пользователю в
                    использовании Сайта.
                </p>
                <p>
                    Владелец вправе размещать рекламную и/или иную информацию в любом разделе Сайта.
                </p>
                <p>
                    Владелец обязан не осуществлять следующих действий:
                </p>
                <ul>
                    <li>Размещать любые файлы, которые содержат или могут содержать вирусы и другие вредоносные
                        программы;
                    </li>
                    <li>Описывать или пропагандировать преступную деятельность, размещать инструкции или руководства по
                        совершению преступных действий;
                    </li>
                    <li>Размещать на Сайте любую информацию, нарушающую исключительные права Пользователей или третьих
                        лиц.
                    </li>
                </ul>
                <p>
                    Владелец вправе не заниматься предварительной модерацией или цензурой комментариев и отзывов
                    Пользователей и предпринимать действия по защите прав и интересов лиц и обеспечению соблюдения
                    требований законодательства Украины только после обращения заинтересованного лица к Владельцу в
                    установленном Владельцем порядке и/или иных случаях, предусмотренных Соглашением.
                </p>
                <p>
                    Владелец не несет ответственность за получение доступа третьими лицами к личной информации
                    Пользователей, если такой доступ стал возможен по вине Пользователей или в результате неправомерных
                    действий третьих лиц.
                </p>
                <h2>Персональные данные</h2>
                <p>
                    Перед заполнением своих персональных данных, Пользователь дает свое согласие Владельцу на сбор и
                    обработку (накопление, хранение, адаптирование, восстановление, использование, распространение,
                    обезличивание и уничтожение) указанных данных, а именно: фамилия, имя и отчество, логин и пароль
                    доступа электронной почты, номера контактного телефона, адреса, даты и места рождения,
                    идентификационного номера, пол, гражданство; страна проживания, место работы, должность) с целью
                    обеспечения реализации отношений в сфере защиты прав потребителей, в сфере рекламы и маркетинговых
                    исследований, а также дает свое согласие на передачу (распространение) его данных другим
                    транспортно-экспедиторским и курьерским организациям, любым Банкам и / или финансовым учреждениям и
                    иным третьим лицам (без ограничения) по усмотрению ООО «МИС-Cервис». Данное положение действует без
                    ограничения срока действия.
                </p>
                <p>
                    Источником сбора персональных данных является информация, сознательно и добровольно предоставлены
                    Пользователем как субъектом персональных данных в целях использования сервисов Сайта.
                </p>
                <p>
                    Владельцем и распорядителем Ваших персональных данных является ООО «МИС-Cервис» (код ЕГРПОУ 43826536).
                    Местонахождение: 49100, г. Днипро, пр.Героев, д. 11Л, каб. 321. База персональных данных
                    «Клиенты» находится по адресу ООО «МИС-Cервис»: 49100, г. Днипро, пр.Героев, д. 11Л, каб. 321.
                </p>
                <p>
                    Субъект персональных данных в соответствии с Законом Украины «О защите персональных данных», имеет
                    право:
                </p>
                <ul>
                    <li>знать об источниках сбора, местонахождение своих персональных данных, цели их обработки,
                        местонахождение или место жительства (пребывания) владельца или распорядителя персональных
                        данных или дать соответствующее поручение о получении этой информации уполномоченным им лицам,
                        кроме случаев, установленных законом;
                    </li>
                    <li>получать информацию об условиях предоставления доступа к персональным данным, включая информацию
                        о третьих лицах, которым передаются его персональные данные;
                    </li>
                    <li>на доступ к своим персональным данным;</li>
                    <li>получать не позднее, чем за тридцать календарных дней со дня поступления запроса, кроме случаев,
                        предусмотренных законом, ответ о том, обрабатываются или хранятся его персональные данные, а
                        также получать содержание его персональных данных, которые хранятся;
                    </li>
                    <li>выдвигать мотивированное требование владельцу персональных данных с возражением против обработки
                        своих персональных данных;
                    </li>
                    <li>выдвигать мотивированное требование об изменении или уничтожение своих персональных данных любым
                        владельцем и распорядителем персональных данных, если эти данные обрабатываются незаконно или
                        они недостоверны;
                    </li>
                    <li>на защиту своих персональных данных от их незаконной обработки и случайной потери, уничтожения,
                        повреждения в связи с умышленным сокрытием, непредоставлением или несвоевременным их
                        предоставлением, а также на защиту от предоставления сведений, которые являются недостоверными
                        или порочащих честь, достоинство и деловую репутацию физического лица;
                    </li>
                    <li>обращаться с жалобами на обработку своих персональных данных в органы государственной власти и к
                        должностным лицам, которые уполномочены обеспечивать защиту персональных данных, или в суд;
                    </li>
                    <li>применять средства правовой защиты в случае нарушения законодательства о защите персональных
                        данных;
                    </li>
                    <li>вносить оговорки об ограничении прав на обработку своих персональных данных при предоставлении
                        согласия;
                    </li>
                    <li>отозвать согласие на обработку персональных данных; знать механизм автоматической обработки
                        персональных данных;
                    </li>
                    <li>на защиту от автоматизированного решения, которое имеет для него правовые последствия.</li>
                </ul>
                <p>
                    Пользователь соглашаясь на сбор и обработку своих персональных данных, подтверждает, что уведомлен о
                    включении информации о нем в базу персональных данных с вышеуказанной целью, о своих правах,
                    определенных Законом Украины «О защите персональных данных», а также о лицах, которым его данные
                    передаются для выполнения вышеуказанной цели.
                </p>
                <p>
                    Пользователь подтверждает, что полностью понимает целевое назначение Сайта, обработки его данных.
                </p>
                <p>
                    Ознакомиться с политикой конфиденциальности и защиты персональных данных возможно по&nbsp;
                    <a href="https://www.apteka24.ua/terms/">
                        ссылке
                    </a>
                </p>
                <h2>Ответственность. Ограничение ответственности</h2>
                <p>
                    Пользователь прямо соглашается, что использует Информационные материалы о Лекарственных средствах,
                    размещенные на Сайте <a href="/">www.apteka24.ua</a>, служат для ознакомления и не могут служить
                    рекомендацией по применению или источником информации по самолечению и могут быть применены
                    исключительно по рецепту врача и под врачебным наблюдением, если другое прямо не предусмотрено
                    действующим законодательством Украины.
                </p>
                <p>
                    Сайт не предназначен для Пользователей, не достигших совершеннолетия и для недееспособных
                    Пользователей. В случае случайного визита на Сайт, такие Пользователи обязаны покинуть его. Владелец
                    сайта не несет ответственности за какие-либо нежелательные последствия в случае невыполнения
                    настоящего требования Пользователями.
                </p>
                <p>
                    Пользователи Сайта из юрисдикций, иных, нежели Украина, должны использовать Информацию о
                    Лекарственных средствах в соответствии с правилами, которые действуют на территории таких
                    юрисдикций.
                </p>
                <p>
                    Владелец Сайта не несет ответственности за возможный ущерб, нанесённый вашему здоровью, в связи с
                    самостоятельным использованием Информации, размещенной на Сайте. Пользователь полностью отвечает за
                    любую неправильную трактовку, которая может возникнуть вследствие просмотра, чтения или копирования
                    материалов, содержащихся на Сайте и таким образом никакое юридическое или физическое лицо не может
                    быть ответственно за использование упомянутых материалов. Ни при каких обстоятельствах
                    ответственность за последствия, которые прямо или косвенно повлекло за собой использование Контента
                    Сайта, не может возлагаться на Владельца Сайта и быть основанием для судебного преследования.
                </p>
                <p>
                    Услуги Сайта предоставляются по принципу &laquo;как есть&raquo; без гарантий любого рода, как прямых
                    , так и косвенных. Пользователь в добровольном порядке отказывается от судебного и административного
                    преследования Владельца Сайта и каких-либо требований о возмещении возможного вреда, причиненного
                    здоровью Пользователя.
                </p>
                <p>
                    Контент Сайта,&nbsp;предоставляется&nbsp;без гарантии того, что он не может содержать ошибки.
                    Владелец Сайта имеет право изменять Контент Сайта, включая без всяких ограничений перемещение или
                    удаление Контента Сайта, по своему личному усмотрению, по любой причине или без причины, в любое
                    время и без предварительного объявления о таких изменениях.
                </p>
                <p>
                    Владелец гарантирует не разглашать данные Пользователя, полученные при регистрации, за исключением
                    случаев, предусмотренных законодательством Украины.
                </p>
                <p>
                    Пользователь полностью ответственен за сохранность своего Логина и Пароля и за убытки, которые могут
                    возникнуть по причине их утраты или несанкционированного использования его Учетной записи. Владелец
                    не несет ответственности и не возмещает убытки, возникшие по причине несанкционированного
                    использования третьими лицами Логина и Пароля Учетной записи Пользователя. Все действия, совершенные
                    посетителем, авторизовавшимся с Логином и Паролем Пользователя, рассматриваются как действия
                    Пользователя. Пользователь несет полную ответственность за все действия, совершенные авторизованным
                    лицом от его имени.
                </p>
                <p>
                    Владелец не несет ответственности за любой ущерб электронным устройствам Пользователя или иного
                    лица, мобильным устройствам, любому другому оборудованию или программному обеспечению, вызванный или
                    связанный с использованием Сайта.
                </p>
                <p>
                    Владелец не несет ответственности перед Пользователем или любыми третьими лицами за действия
                    Пользователя на Сайте.
                </p>
                <p>
                    Владелец не несет ответственности за нарушение Пользователем Соглашения и оставляет за собой право
                    по своему собственному усмотрению, а также при получении информации от любых третьих лиц о нарушении
                    Пользователем Соглашения или прав и интересов третьих лиц, изменять (модерировать) или удалять любую
                    публикуемую Пользователем информацию, а также приостанавливать, ограничивать или прекращать доступ
                    Пользователя ко всем или к любому из разделов Сайта в любое время по любой причине или без
                    объяснения причин, с предварительным уведомлением или без такового, не отвечая за любой вред,
                    который может быть причинен Пользователю таким действием.
                </p>
                <p>
                    В случае предъявления третьими лицами претензий к Владельцу, связанных с использованием
                    Пользователем Сайта, Пользователь обязуется своими силами и за свой счет урегулировать указанные
                    претензии с третьими лицами, оградив Владельца от возможных убытков и разбирательств.
                </p>
                <p>
                    Сайт может содержать ссылки на другие ресурсы сети Интернет. Пользователь признает и соглашается с
                    тем, что Владелец не контролирует и не несет никакой ответственности за доступность этих ресурсов и
                    за их содержание, а также за любые последствия, связанные с использованием этих ресурсов. Любые
                    переходы по ссылкам, осуществляемые Пользователем, последний производит на свой страх и риск.
                </p>
                <p>
                    Владелец Сайта не гарантирует бесперебойной и безошибочной работы Сайта; также не гарантируется, что
                    таковыми будут как результаты, полученные в ходе использования Сайта, так и точность, и применимость
                    его материалов.
                </p>
                <h2>Внешние ссылки</h2>
                <p>
                    В соответствии с условиями и положениями, изложенными в настоящем Соглашении, Владелец предоставляет
                    Пользователю неисключительную, ограниченную, отзывную, безвозмездную лицензию на размещение
                    гипертекстовой справочной ссылки (далее по тексту - &quot;Ссылка&quot;) на Сайт исключительно в
                    некоммерческих целях, не выходящих за рамки соединения сайта Пользователя с Сайтом.
                </p>
                <p>
                    Пользователь не вправе:
                </p>
                <ul>
                    <li>Размещать Ссылку или пользоваться ею таким образом, который приведет к выводу Сайта или любого
                        его элемента на экран в виде отдельного кадра в окне, либо к появлению ассоциаций с любого рода
                        рекламой или спонсорской программой, не имеющей отношения к Сайту, либо к иному включению
                        информационного наполнения Сайта в состав какого бы то ни было стороннего сайта;
                    </li>
                    <li>Размещать или использовать встроенные ссылки на какой-либо файл данных, входящий в состав
                        Сайта;
                    </li>
                    <li>Видоизменять какой-либо элемент Сайта, блокировать или иным образом препятствовать его
                        отображению;
                    </li>
                    <li>Ссылаться на Сайт через какой-либо сторонний унифицированный указатель ресурсов (URL) или
                        сайт-дублер;
                    </li>
                    <li>Размещать ссылки на Сайт в том случае, если содержание сайта Пользователя выглядит в глазах
                        здравомыслящего лица непристойным, клеветническим, содержащим в себе домогательства, глубоко
                        оскорбительным, злонамеренным или любым иным образом незаконным и/или нарушающим охраняемые
                        законодательством права.
                    </li>
                </ul>
                <h2>Торговые марки</h2>
                <p>
                    Зарегистрированные торговые марки, знаки для товаров и услуг, коммерческие наименования, упоминаемые
                    на Сайте, являются собственностью их соответствующих правообладателей.
                </p>
                <h2>Заключительные положения</h2>
                <p>
                    Соглашение заключается на неопределенный срок и распространяет свое действие на Пользователей,
                    осуществляющих доступ к Сайту и его использование, как до даты опубликования Соглашения, так и после
                    даты его опубликования на Сайте.
                </p>
                <p>
                    Если по тем или иным причинам какие-либо из условий Соглашения являются недействительными или не
                    имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных
                    условий Соглашения.
                </p>
                <p>
                    Соглашение и отношения между Владельцем и Пользователем регулируются в соответствии с
                    законодательством Украины. Стороны Соглашения обязуются подчиняться исключительной юрисдикции судов
                    Украины при возникновении спорных вопросов юридического характера, связанных с Соглашением или
                    Сайтом.
                </p>
                <p>
                    Стороны согласились, что все споры, возникающие из отношений Сторон, регулируемых настоящим
                    Соглашением, должны разрешаться в компетентном суде по месту нахождения Владельца с обязательным
                    соблюдением досудебного претензионного порядка урегулирования споров, установленного Соглашением.
                </p>
                <p>
                    Если Пользователь не до конца понимает условия данного Соглашения или не согласен с любым из
                    пунктов, изложенных в Соглашении, он должен покинуть страницы Сайта.
                </p>`
    },
    orderReturn: {
        title: "Условия возврата товаров",
        content: `
                <p>
                    Согласно
                    <a
                        href="https://zakon.rada.gov.ua/laws/show/z0782-05#Text"
                        target="_blank"
                    >
                        приказу МОЗ от 19 июля 2005 г. №360
                    </a>
                    и 
                    <a
                        href="https://zakon.rada.gov.ua/laws/show/172-94-%D0%BF#Text"
                        target="_blank"
                    >
                        Постановлению КМУ от 19.03.1994 г. №172
                    </a>: «Лекарственные средства и изделия медицинского 
                    назначения надлежащего качества, отпущенные из аптек и их структурных подразделений, 
                    возврату не подлежат».
                </p>
                
                <p>
                    Согласно <a
                        href="https://zakon.rada.gov.ua/laws/show/1023-12#Text"
                        target="_blank"
                    >
                        ст. 8. Закона Украины «О защите прав потребителей»
                    </a>: 
                    «В случае обнаружения в течение установленного гарантийного срока  существенных недостатков, 
                    возникших по вине производителя товара (продавца, исполнителя), или фальсификации товара, 
                    подтвержденных при необходимости выводом экспертизы, потребитель, в порядке и в сроки, 
                    установленные законодательством, и на основании обязательных для сторон правил или договора, 
                    вправе по своему выбору требовать от продавца или производителя:
                </p>
                
                <ul>
                    <li>
                        расторжения договора и возврата уплаченной за товар денежной суммы;                                            
                    </li>
                                    
                    <li>
                        требовать замены товара на такой же товар или на аналогичный из числа имеющихся у продавца (производителя)».
                    </li>                
                </ul>
                
                <p>
                    Для возврата лекарственного средства или изделия медицинского назначения НЕнадлежащего качества 
                    необходимо предоставить фискальный чек и доказательство того, что лекарственное средство 
                    некачественное — заключение экспертизы, распоряжение Гослекслужбы о запрете обращения 
                    лекарственного средства и т.д. Официальным органом в сфере контроля качества лекарственных 
                    средств является 
                    <a
                        href="https://www.dls.gov.ua/"
                        target="_blank"
                    >
                        Государственная служба Украины по лекарственным средствам и контролю за 
                        наркотиками
                    </a> 
                    (Гослекслужба).
                </p>
                
                <p>
                    Соответственно 
                    <a
                        href="https://zakon.rada.gov.ua/laws/show/1002-2021-%D0%BF#Text"
                        target="_blank"
                    >
                        Постановлению КМУ от 22.09.2021 г. № 1002
                    </a> 
                    «Потребитель имеет право отказаться от доставленного лекарственного средства во время получения 
                    заказа и вправе потребовать возврата средств от лицензиата, осуществившего продажу такого
                    лекарственного средства в случае, если:
                </p>
                
                <ul>
                    <li>
                        повреждена первичная и/или вторичная упаковка лекарственного средства;
                    </li>
                    
                    <li>
                        истек срок годности лекарственного средства;
                    </li>
                    
                    <li>
                        уполномоченным органом опубликован запрет на реализацию и применение лекарственного средства;
                    </li>
                    
                    <li>
                        доставленный заказ не соответствует заказанному по количеству или составу, наименованию, дозировке, форме выпуска, цене.
                    </li>
                </ul>
                
                <p>
                    Потребитель имеет право вернуть его сотруднику службы доставки лицензиата, осуществившего доставку, без оплаты доставленного лекарственного средства и/или услуг по его доставке и/или требовать надлежащего выполнения заказа».
                </p>
                `
    },
    warranty: {
        title: "Гарантии качества",
        content: `
                <p>
                    Как электронная медицинская информационная система мы видим своей задачей организацию выбора и доставки качественной продукции аптечного ассортимента клиенту. Поэтому мы сотрудничаем с надежными аптеками-партнерами, которым товары медицинского назначения поставляют только крупные и проверенные дистрибьюторы в Украине.
                </p>

                <p>
                    В свою очередь наши аптеки-партнеры осуществляют проверку всех поступающих товаров через систему трёхуровневого контроля:
                </p>
                
                <ul>
                    <li>
                        каждый этап реализации лекарственных средств сопровождается четким контролем температурного режима и влажности (первая проверка происходит при поступлении продукции на склад, вторая — при выходе со склада в аптеку, третья — при приеме товара в аптеке);
                    </li>
                    
                    <li>
                        в аптеках происходит отслеживание продукции с истекающим сроком годности, товар с истекающим сроком годности изымается заранее из реализации;
                    </li>
                    
                    <li>
                        при поступлении продукции в аптеки-партнеры уполномоченное лицо проводит входной контроль, 
                        проверяет лекарственные средства на отсутствие в 
                        <a href="http://pub-mex.dls.gov.ua/QLA/DocList.aspx" target="_blank">Реестре документов качества лекарственных средств</a>, 
                        обращение которых запрещено в Украине, а также проводит визуальный контроль по таким 
                        показателям как «Маркировка», «Упаковка», «Срок годности».
                    </li>
                </ul>

                <p>
                    К тому же, в каждом аптечном заведении, с которым мы сотрудничаем, покупатель может попросить предоставить сертификаты качества на продукцию и информацию о производителе или дистрибьюторе — и эти документы будут предоставлены.
                </p>
                `,
        qualityAssurance: `Гарантии качества подтверждают сертификаты GDP`
    },
    loyaltyRules: {
        title: `Правила участия в бонусной Программе лояльности «Морковки»`,
        description: `Данные Правила для принятия участия в бонусной Программе лояльности «Морковки» (далее — «Правила») определяют и устанавливают официальные условия для участия в бонусной Программе «Морковки» (далее — «Программа»), которые устанавливают период, условия, а также порядок участия в Программе. В создание настоящих правил положен Гражданский кодекс Украины, Закон Украины «Об информации», Закон Украины «О защите персональных данных», а также прочие нормативно-правовые акты Украины и внутренние положения ООО «МИС-Cервис».`,
        terms: {
            title: `Определение терминов настоящих Правил участия в Программе лояльности`,
            list: `
                <li>«Организатор» Программы — ООО «МИС-Cервис», 49100, г. Днипро, пр.Героев, д. 11Л, каб. 321.</li>
                <li>«Программа» — это программа лояльности на apteka24.ua, подразумевающая использование бонусов, целью которой определяется поощрение постоянных покупателей, которым предоставляются специальные условия для приобретения товаров на сайте apteka24.ua.</li>
                <li>«Участник» Программы — принимать участие в Программе могут только дееспособные физические лица, достигшие совершеннолетия. Для того, чтобы принять участие в Программе, Участник предоставляет свое согласие следующим образом: регистрируясь либо совершая покупки на сайте apteka24.ua в соответствии с действующими Правилами. Участие такого лица должно быть подтверждено Организатором.</li>
                <li>«Учетная запись по номеру мобильного телефона» (учетная запись по НМТ) — учетная запись Участника в информационной системе Организатора, являющаяся виртуальной. В системе номер учетной записи всегда соответствует украинскому номеру мобильного телефона Участника.</li>
                <li>«Кэшбек» — это бонусы, которые возвращаются на «Счет» Участника в рамках программы лояльности «Морковки» за совершенные покупки на сайте apteka24.ua. Также бонусы начисляются в виде поощрения за дополнительные активности в рамках программы лояльности «Морковки», предусмотренные Организатором. Бонусы «Кэшбек» активируются для списания на 15-ый день после того, как будут начислены. </li>
                <li>«Купоны» — так называются специализированные личные промо-предложения для участников программы лояльности «Морковки» с ограниченным сроком действия и которые являются дополнительным поощрением. Купоны могут быть предоставлены Участнику как в виде автоматических начислений на его личный счет, так и в виде специальных текстовых кодов. В первом случае, если купон является автоматическим начислением на счет Участника, то он активируется сразу же, как Участник выполнит условия, необходимые для его списания. Во втором случае, когда купон предоставляется в виде текстового кода, Участнику, чтобы воспользоваться данным купоном, необходимо ввести текстовый код в момент оформления заказа на сайте apteka24.ua в процессе совершения покупки. </li>
                <li>«Счет» — в информационной системе Организатора является совокупностью данных о процессе начисления и списания бонусов согласно учетной записи Участника.</li>
                <li>«Поощрение» — так называется возможность Участника использовать Бонусы в качестве скидки при приобретении товаров (кроме некоторых ограничений на покупку товаров, установленных Организатором) на сайте apteka24.ua в соответствии с данными Правилами Программы. К поощрениям относятся: бонусы, купоны и подарки, предусмотренные организаторами программы. </li>
                <li>«Транзакция» — это проводимая Участником операция, в результате которой происходит начисление либо же списание Бонусов со Счета в информационной системе Организатора.</li>
                <li>«Каналы коммуникации Программы» — такими каналами являются непосредственно сайт apteka24.ua, горячая линия 0800302244 и другие средства коммуникации, применяемые для контакта и коммуникации между Участником и Организатором.</li>
                `
        },
        generalProvisions: {
            title: `Общие положения Программы лояльности`,
            list: `
                <li>Программу лояльности «Морковки» необходимо считать договором присоединения, который нужно трактовать согласно статье 634 Гражданского кодекса Украины.</li>
                <li>Программа лояльности не является азартной игрой или иной услугой в сфере игорного бизнеса.</li>
                <li>Программа лояльности «Морковки» действует исключительно только на официальном сайте «Apteka24.ua» — apteka24.ua.</li>
            `
        },
        conditions: {
            title: `Условия, необходимые для участия в Программе лояльности`,
            list: `
                <li>Участником Программы лояльности может стать только физическое лицо, которое зарегистрировалось на сайте apteka24.ua, обязательно указало номер своего мобильного телефона от украинского GSM-оператора и/или совершило покупку товаров на сайте apteka24.ua вне зависимости от суммы оплаты. Все действия должны быть совершены согласно условиям настоящих Правил.</li>
                <li>Программа может быть использована исключительно физическими лицами, достигшими совершеннолетия. Бонусы Программы запрещено продавать, за них невозможно получить компенсацию в виде денежных средств, также их невозможно отчуждать никаким любым другим способом.</li>
                <li>Юридические лица никоим образом не могут участвовать в Программе.</li>
                <li>Основным идентификатором Участника Программы в информационной системе Организатора является виртуальная учетная запись, соответствующая номеру мобильного телефона Участника от украинского GSM-оператора (учетная запись по НМТ).</li>
                <li>Информацию об условиях Программы можно получить на сайте apteka24.ua или через Каналы коммуникации Программы.</li>
                <li>Датой начала участия в Программе является дата активации учетной записи по номеру мобильного телефона в информационной системе Организатора Участником Программы согласно настоящим Правилам.</li>
                <li>Учетная запись в Программе лояльности «Морковки», созданная согласно номеру мобильного телефона Участника, в качестве идентификатора Участника Программы в системе Организатора действительна только в течение срока действия Программы.</li>
                <li>Учетную запись Участника по номеру мобильного телефона можно заблокировать при необходимости в следующих случаях: при утере мобильного телефона, при утере sim-карты, а также по причине повреждения sim-карты. Для блокировки, а также для ее разблокирования Участнику необходимо обратиться к Организатору Программы на электронную почту info@apteka24.ua или же позвонить на горячую линию по номеру 0800302244 с соответствующим обращением. Участнику потребуется указать свою фамилию и имя, номер мобильного телефона, причину блокировки учетной записи по НМТ, а также пройти идентификацию личности согласно одному из предложенных сотрудником компании «Apteka24.ua» способом.</li>
                <li>Для разблокирования учетной записи по номеру мобильного телефона необходимо обратиться к Организатору Программы на электронную почту info@apteka24.ua с соответствующей заявкой на разблокирование учетной записи по НМТ. Разблокирование возможно только тогда, когда информационные данные физического лица (ФИО, дата рождения, номер мобильного телефона), подающего заявку на разблокирование, совпадают с данными, которые были указаны Участником при регистрации в информационной системе Программы.</li>
                <li>Организатор Программы, разблокируя учетную запись, не несет ответственности за все операции, которые проводились на счете до момента блокировки учетной записи.</li>
                <li>В случае изменения номера мобильного телефона Участнику необходимо обратиться к Организатору Программы на электронную почту info@apteka24.ua с соответствующим обращением. Для того, чтобы изменить номер Учетной записи, нужно указать прежний номер телефона, а также новый номер мобильного телефона. При этом в информационной системе Организатора для данного Участника деактивируется старая учетная запись по прежнему номеру мобильного телефона и активируется новая учетная запись по новому номеру мобильного телефона. Обязательно происходит соответствующее сохранение состояния счета.</li>
                <li>В том случае, если Участник несвоевременно проинформировал Организатора о том, что он изменил номер мобильного телефона, то Организатор Программы не несет ответственности за все те операции, которые могли проводиться на счете Участника до поступления соответствующего информирования об изменении номера телефона.</li>
            `
        },
        organizerResponsibilities: {
            title: `Обязанности и права Организатора Программы лояльности`,
            list: `
                <li>Организатор оставляет за собой абсолютное право в любой момент приостановить Программу, отменить либо же изменить условия Программы. При закрытии Программы все Бонусы, которые успел набрать участник, могут быть использованы им в течение 30 (тридцати) календарных дней после размещения соответствующего объявления на сайте о том, что программа закрывается.</li>
                <li>
                    Организатор в любой момент обладает правом отказать клиенту в активации Учетной записи по его номеру мобильного телефона, удалить либо же прекратить действие Учетной записи при следующих обстоятельствах, которые могут быть шире указанных:
                    <ul>
                        <li>использование или попытка использования Учетной записи тем способом, который не предусмотрен условиями Программы;</li>
                        <li>при возникновении любого обоснованного подозрения в том, что Участник производит злоупотребления относительно своего участия в Программе;</li>
                        <li>если Участник не проводит никаких операций на своей Учетной записи в течение 36 месяцев;</li>
                        <li>любые другие весомые обстоятельства, которые Организатор может считать значимыми для отказа в активации или для прекращения активности учетной записи.</li>
                    </ul>
                </li>
                <li>Организатор не несет ответственности за любые потери или убытки, которые могут возникать в результате подобных действий.</li>
                <li>Организатор имеет право списывать со счета Участника Программы Бонусы, начисление которых было ошибочным в связи с неправильной работой ПО (программного обеспечения): например, если Бонусы были начислены в связи с покупкой, которая была отменена, либо в случае возврата товара, а также тогда, когда Участник будет уличен в мошенничестве и/или совершит недобросовестные действия с Учетной записью или со своим бонусным балансом.</li>
            `
        },
        registration: {
            title: `Процедура регистрации в программе`,
            list: `
                <li>Регистрация в Программе осуществляется следующим образом: покупатель, становясь Участником Программы, регистрируется по номеру мобильного телефона или же совершает покупку на сайте apteka24.ua. Сумма покупки может быть любой.</li>
                <li>После регистрации и после того, как произойдет передача данных по покупке клиента в информационную систему Организатора, для клиента активируется Учетная запись по номеру мобильного телефона.</li>
            `
        },
        bonusesAccrual: {
            title: `Как происходит начисление бонусных поощрений`,
            list: `
                <li>Перед тем, как Участник проведет какую-либо транзакцию для оплаты товаров, он должен указать личный мобильный номер. После чего происходит начисление бонусного поощрения через информационную систему Организатора на счет Участника.</li>
                <li>Существуют специальные бонусы, которые могут быть начислены Участнику Организатором при проведении рекламных кампаний, специальных акций и предложений от Программы. Такие бонусные начисления обладают ограниченным сроком действия, который соответствует срокам, в период которых осуществляется акция. После того, как акция будет закончена, данные бонусы исчезают со счета Участника и более он не сможет ими воспользоваться.</li>
                <li>Бонусы будут аннулированы в том случае, если происходит возврат ранее приобретенного товара, за покупку которого были начислены Бонусы. Если товар подлежит процедуре обмена, то бонусы, начисленные за его приобретение, аннулируются и начисляются на тот товар, который приобретается. Обмен и возврат товара может быть проведен только в рамках норм, установленных Законом Украины «О защите прав потребителей». </li>
                <li>Приостановление возврата денежных средств может быть произведено Организатором в том случае, если в результате проведения транзакции у Участника возникает отрицательный баланс на бонусном счете. Это приводит к тому, что период рассмотрения заявления продлевается на 14 календарных дней. Если возникает отрицательный баланс на бонусном счете Участник, то Участник должен компенсировать размер предоставленной скидки в денежном эквиваленте.</li>
                <li>Начисление бонусов за приобретение товара на сайте apteka24.ua, на который распространяются условия Программы лояльности, осуществляется после осуществления оплаты данной покупки. Бонусы активируются и становятся возможными для списания на 15-ый день после оплаты товара.</li>
                <li>При совершении покупки на сайте apteka24.ua Участник должен проверить правильность указанных деталей заказа, а также номер своего мобильного телефона. При возникновении спорных вопросов для их успешного решения, Участник должен обратиться к представителю Организатора, сообщив ему номер заказа и предъявив, в случае необходимости, оригинал фискального чека.</li>
                <li>Организатор оставляет за собой право внесения изменений в <a href="${links.loyaltyBonusRules}">условия начисления Бонусов</a> без уведомления Участников Программы.</li>
                <li>Бонусы могут быть начислены на счет Участника Программы только при условии, что для этого существуют все необходимые технические и/или другие возможности: наличествует интернет, нет перебоя с электроэнергией, правильно функционирует программное обеспечение. Если данные условия не соблюдены или их соблюдение невозможно для начисления бонусов, то бонусы будут начислены на счет Участника после того, как будут возобновлены все необходимые возможности.</li>
            `
        },
        bonusesUsage: {
            title: `Правила использования поощрений (бонусов)`,
            list: `
                <li>Бонусы  становятся доступным для использования после того, как были начислены на бонусный счет Участника, кроме Бонусов, условия по которым определяются соответствующей Акцией.</li>
                <li>Срок действия бонусов на бонусном счете Участника составляет 180 календарных дней с момента их активации, кроме Бонусов, условия по которым определяются соответствующей Акцией.</li>
                <li>Все бонусы, накопленные Участником на бонусном счете и доступные к списанию, Участник может использовать для экономии и оплаты товаров в качестве скидки, однако оплатить бонусами можно не более 50% от стоимости товара, который будет приобретаться согласно Программе на сайте apteka24.ua (исключение составляют только ограничения на приобретение товаров, установленные Организатором).</li>
                <li>Оплата товаров за счет списания бонусов (в случае, если имеются активированные бонусы на счету Участника) возможна только тогда, когда Участник пройдет процедуру верификации по номеру мобильного телефона. Для этого Участнику на его мобильный номер приходит либо SMS-сообщение, либо Viber-сообщение от альфа-имени apteka24.ua c четырехзначным кодом подтверждения, который необходим для списания бонусов. Участник вводит данный код в специальное поле формы, появляющейся в момент оформления заказа с использованием бонусов. Срок действия подтверждающего кода — сутки (24 часа). Данный код может использоваться только один раз.</li>
                <li>Участник может использовать Бонусы только тогда, когда для этого есть все необходимые технические и/или другие условия: наличествует интернет-соединение, бесперебойная электроэнергия, корректно работает программное обеспечение.</li>
                <li>Использование Бонусов со Счета Участника в виде списания осуществляется следующим образом: 1 бонус условно приравнивается к 1 гривне скидки при покупке товара по условиям Программы.</li>
                <li>Организатор оставляет за собой право вносить изменения в перечень товаров, во время приобретения которых Участник может использовать Бонусы, при этом предварительное уведомление Участников не обязательно.</li>
                <li>Согласно количеству набранных Бонусов на счете Участника, Организатор предоставляет Участнику право получить скидку при покупке товара по заявленным условиям.</li>
                <li>Организатор Программы определяет и утверждает товары, покупая которые можно использовать накопленные Бонусы.</li>
                <li>Бонусы можно использовать как скидку (экономию) стоимости товара, но не более 50% от стоимости товара, который приобретается в рамках и по условию Программы.</li>
                <li>Бонусы не являются и не могут быть средством платежа, не являются эквивалентом любого вида валюты или ценной бумаги.</li>
                <li>Доступные к списанию Бонусы, накопленные Участником в рамках Программы, никоим образом не могут быть переведены в денежный эквивалент и выданы Участнику наличными денежными средствами.</li>
                <li>Организатор Программы не несет ответственности за уплату Участником налогов и выполнения иных финансовых обязательств, связанных с участием в Программе. Все оплаты осуществляются Участником Программы самостоятельно и за собственный счет.</li>
                <li>Бонусы, которые Участник не использовал в течение 180 дней с момента активации и/или во время проведения Акции, аннулируются и списываются со счета Участника. Списание бонусных поощрений происходит в хронологическом порядке, начиная от даты их активации.</li>
                <li>Организатор оставляет за собой право внесения изменений в <a href="${links.loyaltyBonusRules}">условия списания Бонусов</a> без уведомления Участников Программы.</li>
            `
        },
        violationConsequences: {
            title: `Последствия нарушения Участником Правил Программы лояльности `,
            list: `
                <li>
                    Организатор оставляет за собой право прервать участие в Программе любого Участника без уведомления путем блокировки учетной записи в случаях недобросовестного использования учетной записи, если Участник:
                    <ul>
                        <li>не придерживается действующих Правил участия;</li>
                        <li>предоставляет недостоверные данные или дезинформацию;</li>
                        <li>принимает участие в схемах мошенничества в рамках действия Программы.</li>
                    </ul>
                </li>
                <li>В случае уличения Участника в нарушениях, указанных пункте 8.1., его участие в Программе прекращается, Учетная запись блокируется, а накопленная бонусная сумма аннулируется.</li>
                <li>В случае возникновения претензий, связанных с блокировкой Учетной записи, прекращением участия в Программе и аннулированием бонусных накоплений, для разблокирования Учетной записи клиент должен подать заявку на электронную почту  info@apteka24.ua и предъявить чеки на покупки, осуществленные при помощи его Учетной записи. После рассмотрения претензии, Организатор принимает решение о возможности разблокировки учетной записи.</li>
            `
        },
        otherConditions: {
            title: `Прочие условия `,
            list: `
                <li>Программа лояльности «Морковки», проводимая apteka24.ua, является долгосрочной.</li>
                <li>
                    <p>Правила Программы строго регулируются действующим законодательством Украины. При расчетах с Участником Организатор имеет право временно отказать Участнику в начислении и/или использовании Бонусов, но только в том случае, если на момент обращения Участника касательно использования Бонусов, у Организатора отсутствует технические или иные возможности для начисления, активации или списания.</p>
                    <p>Участник соглашается со  всеми данными правилами Программы, если он совершает любое активное действие на сайте apteka24.ua (например регистрация, покупка, оставление отзыва и т.д.)</p>
                </li>
                <li>Организатор гарантирует, что во время получения, хранения, обработки и использования полученной от Участника персональной информации он будет придерживаться положений Правил и норм действующего законодательства Украины. В связи с этим Участник подтверждает, что он ознакомился и полностью (то есть, однозначно) согласился с условиями Программы, любыми оговорками относительно получения, хранения, обработки и использования информации, указанными в Политике конфиденциальности и защиты персональных данных на сайте apteka24.ua.</li>
                <li>Принимая участие в Программе, каждый Участник предоставляет однозначное согласие на использование его имени, фамилии, фотографии или других материалов о нем в рекламных целях, в том числе право публикации его имени и фотографии в средствах массовой информации, любых печатных, аудио- и видеоматериалах, без ограничения территории и сроков такого использования. Такое использование не оплачивается Организатором или любыми третьими лицами.</li>
                <li>
                    <p>Участник Программы предоставляет однозначное согласие на то, что все данные, в том числе персональные, и данные о сделанных Участником покупках могут быть получены, сохранены, обработаны и использованы Организатором и другими уполномоченными Организатором организациями и третьими лицами без ограничения по сроку и территории, в том числе и за пределами Украины, с целью реализации Программы, создания новых программ лояльности, изучение потребительского спроса в маркетинговых, информационных, рекламных или иных аналогичных целях, предоставлении Участнику рекламных и информационных материалов без ограничений.</p>
                    <p>«Apteka24.ua» не несет ответственности за вред или ущерб, нанесенные участнику или третьим лицам в результате ошибочного понимания или непонимания условий данных правил, инструкций или указаний о порядке использования сайта, относительно размещения данных и других технических вопросов.</p>
                </li>
                <li>Организатор оставляет за собой право отказать кому-либо в участии в Программе по своему усмотрению, без каких-либо негативных последствий для себя.</li>
                <li>Организатор имеет право в одностороннем порядке вносить изменения в данные Правила без предварительного уведомления Участников Программы.</li>
                <li>Участник считается уведомленным о приостановлении или прекращении действия Программы, а также об изменении Правил Программы, если такие изменения были размещены на Сайте Программы и/или направлены Участнику на указанные в учетной записи адрес электронной почты или номер мобильного телефона. Если от участника не поступил отказ от участия, то считается, что он принял изменения. Обязанность следить за изменениями возлагается на Участника.</li>
                <li>
                    Участник программы лояльности «Морковки» может отказаться от участия в Программе в любое время одним из следующих способов:
                    <ul>
                        <li>подав заявку на электронную почту info@apteka24.ua; </li>
                        <li>обратившись в службу поддержки клиентов Организатора по телефону 0800302244 и проинформировав о своем желании.</li>
                    </ul>
                </li>
            `
        }
    },
    loyaltyBonusRules: {
        title: `Правила начисления и списания бонусов по программе лояльности «Морковки»`,
        bonusesAccrual: {
            title: `Начисление бонусов`,
            list: `
                <li>
                    <b>Начисление бонусов за отзывы.</b> Клиентам начисляется вознаграждение в размере 30 бонусов за каждый их отзыв, который опубликован на сайте и соответствует следующим правилам:
                    <ul>
                        <li>отзыв соответствует <a href="${links.reviewsPostingPolicy}">Политике публикации отзывов;</a></li>
                        <li>отзыв написан на основе личного опыта, а точные фразы и изображения из отзыва не были опубликованы где-либо ранее;</li>
                        <li>объектом отзыва является конкретный товар, аптечная точка, статья блога и т.д., под которым(ой) данный отзыв опубликован;</li>
                        <li>количество отзывов, за которые клиент получил бонусы в текущем месяце, не превышает 10 шт.;</li>
                        <li>бонус единоразово начисляется на баланс одновременно с публикацией его величины в разделе «Мои бонусы» сразу после модерации отзыва;</li>
                        <li>при блокировании отзыва за нарушение правил бонус может быть списан с баланса автора в любой момент.</li>
                    </ul>
                </li>
                <li>
                    <b>Начисление дополнительных бонусов за отзывы.</b> Клиентам начисляется дополнительное вознаграждение в размере 1 бонуса за каждую реакцию «Нравится» на их отзыв, который опубликован на сайте по следующим правилам:
                    <ul>
                        <li>бонусы начисляются не более чем на 5 реакций «Нравится» на один отзыв в сутки;</li>
                        <li>общее количество дополнительных бонусов за каждую реакцию «Нравится» на один отзыв не может превышать 70 бонусов.</li>
                    </ul>
                </li>
                <li>
                    <b>Начисление бонусов (кешбэка) за покупку товара.</b> Клиентам начисляется вознаграждение в размере 2% от стоимости заказа за покупку любого товара на сайте apteka24.ua. Количество бонусов, которые вернутся клиенту, указано в карточке товара специальной инфографикой:<br/>
                    <div class="bonus-cashback">
                        <img src="${links.images.yellowCarrotMini}" alt="bonus" /> Кешбэк <span> 5.27 ₴</span>
                    </div>
                    <p>Начисленные бонусы (кешбэк) становятся доступны к списанию после того, как заказ, за который они начислены, переведен в статус «Выполнен». При отмене клиентом заказа или при отказе от заказа во время получения начисленные бонусы (кешбэк) за данный заказ будут аннулированы.</p>
                </li>
                <li><b>Начисление промокодов/купонов.</b> Клиентам начисляется вознаграждение в виде промокодов и купонов в качестве поощрения за участие в акциях, спецпредложений от производителей и т.д., и представляет собой единоразовую скидку, бесплатную доставку и т.п. Возможность использования промокодов и купонов ограничена по времени и указана в личном кабинете клиента.</li>
                <li>
                    <b>Начисление welcome-бонусов за регистрацию.</b> Клиентам начисляется вознаграждение в виде: 
                    <ul>
                        <li>скидка 10% на первый заказ, совершенный через мобильное приложение «Apteka24.ua»;</li>
                        <li>50 бонусов за регистрацию по номеру телефона в специальной форме на сайте apteka24.ua, если по этому номеру телефона раньше не было покупок или регистраций на сайте apteka24.ua. После ввода номера обязательно нужно ввести проверочный код, полученный клиентом в сообщении в Viber или SMS, иначе бонусы начислены не будут;</li>
                        <li>промокод на скидку 5% за указанный email, если по указанному номеру телефона клиента раньше были покупки или регистрация на сайте apteka24.ua.</li>
                    </ul>
                </li>
                <li>
                    <b>Начисление бонусов за фотообзор («распаковку») товара.</b> Клиентам начисляется вознаграждение в размере 30 бонусов за каждую их «распаковку», которая опубликована на сайте и соответствует следующим правилам:
                    <ul>
                        <li>отзыв соответствует <a href="${links.reviewsPostingPolicy}">Политике публикации отзывов;</a></li>
                        <li>отзыв написан на основе личного опыта, а точные фразы и изображения из отзыва не были опубликованы где-либо ранее;</li>
                        <li>объектом отзыва является конкретный товар, реализуемый на сайте apteka24.ua, аптечная точка, статья блога и т.д., под которым(ой) данный отзыв опубликован;</li>
                        <li>в отзыв добавлено не менее 3-х фото в формате .jpg, .png с разрешением не менее 800х600;</li>
                        <li>запрещается публикация отзыва, фото и изображений, не имеющих информационной ценности, либо в неприемлемом виде (размытые, перевернутые, мелкие и т.п.), либо шокирующего характера, а также скриншоты. Указанные публикации будут удалены модераторами.</li>
                    </ul>
                </li>
            `
        },
        bonusesWriteOff: {
            title: `Списание бонусов`,
            list: `
                <li>
                    <p><b>Оплата бонусами.</b> Бонусами клиент может оплатить до 50% стоимости только тех товаров, которые помечены на сайте специальном значком с морковкой:</p><br/>
                    <div class="bonus-pay"><img src="${links.images.yellowCarrotLarge}" alt="bonus" /> или <img src="${links.images.yellowCarrot}" alt="bonus" /></div>
                    <p>Клиент не может выбирать, какую сумму бонусов потратить — списываются все доступные к использованию бонусы, но не более 50% стоимости товара. Использование бонусов исключает возможность одновременной оплаты текущего заказа с помощью промокода/купона.</p>
                </li>
                <li><b>Использование промокода/купона.</b> При использовании промокода/купона клиент может получить скидку на конкретный товар в заказе, скидку на весь заказ, бесплатную доставку и т.д. — в зависимости от условий используемого промокода/купона. Использование промокода/купона исключает возможность одновременной оплаты текущего заказа с помощью бонусов. Промокодом/купоном можно оплатить до 50% стоимости заказа. Если номинал купона больше 50% стоимости заказа, то купон применяется только на 50% стоимости заказа, оставшаяся неиспользованная часть номинала купона сгорает.</li>
            `
        },
        limitations: {
            title: `Ограничения`,
            list: `
                <li>Нельзя оплачивать бонусами заказы, которые сформированы на самовывоз из аптек-партнеров.</li>
                <li>Начисленные, но не потраченные, бонусы будут сгорать через 180 дней от момента, когда у пользователя появится возможность их тратить.</li>
                <li>Welcome-бонусы и другие бонусы с ограниченным сроком действия сгорают по истечении указанного для них срока действия.</li>
                <li>
                    Администрация сайта имеет право:
                    <ol>
                        <li>заблокировать аккаунт за спам, некорректное поведение, накрутку просмотров/рейтингов/репутации любыми способами (в том числе за провокации на взаимное плюсование отзывов) или за грубые нарушения других пунктов правил;</li>
                        <li>аннулировать бонусы, полученные клиентами в результате мошенничества и/или действий, нарушающих данные правила;</li>
                        <li>требовать необходимые материалы для проверки достоверности и правдивости информации (включая фото объектов и документов);</li>
                        <li>не удалять статьи, ответы и отзывы по запросу авторов. Если Вы согласитесь с правилами и зарегистрируетесь, а в будущем решите покинуть проект, то все Ваши публикации будут оставаться частью проекта;</li>
                        <li>изменять данные правила с целью улучшения качества предоставляемого сервиса с указанием даты вносимых изменений.</li>
                    </ol>
                </li>
            `
        }
    }
};
